import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { getSearchStockDetails } from "../../../services/HomeService";
import {
  getExchangeRateAPI,
  getRapidExchangeRateAPI,
} from "../../../services/commonServices";
import { modifyOpenTrade } from "../../../services/TradeService";
import { toast } from "react-toastify";
import { GetCurrentBalabce } from "../../../services/ProviderServices";
import { GetOpenTradeListByUserId } from "../../../services/TradeService";
import CountryCurrencyList from "../../commonComponent/CountryCurrencyList";
const validationSchemaBuy = Yup.object().shape({
  quantity: Yup.string().required("Quantity is required"),
  amount: Yup.string().required("Amount is required"),
  // providerId: Yup.string().required('Provider is required'),
});
const BuySellUpdateTrade = ({
  portfololioList,
  countryList,
  handleGetOpenTradelist,
  onSave,
  allCountryList,
  buySellTradeData,
  setBuySellTradeData,
  portFolipID,
  userType,
}) => {
  const [buySellInput, setBuySellInput] = useState({
    quantity: "",
    amount: "",
    lotValue: "",
    Lotquantity: "",
  });
  const [stockCurrentData, setStockCurrentData] = useState({
    price: "0",
    profit: "0",
    profitPer: "0",
  });
  const [exchangeRate, setExchangeRate] = useState();
  const [brokerData, setBrokerData] = useState("");
  const { price, profit, profitPer } = stockCurrentData;
  const [popUpShow, setPopUpShow] = useState(false);
  const [stockexrate, setStockExRate] = useState("");
  const [stockcurrencysymbol, setStockCurrencySymbol] = useState("");
  const [askprice, setAskPrice] = useState("");
  const [bidprice, setBidPrice] = useState("");
  const [calculatedask, setCalculatedAsk] = useState("");

  const twoDecimal = (value) => {
    // Check if the value is undefined or null
    if (value === undefined || value === null) {
      return ""; // Or any default value that you want to return in case of undefined/null
    }

    var roundedNumber = String(value); // Convert value to string
    var decimalIndex = roundedNumber.indexOf(".");

    if (decimalIndex !== -1) {
      var decimalPlaces = roundedNumber.length - decimalIndex - 1;
      if (decimalPlaces > 2) {
        roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
      }
    }

    return roundedNumber;
  };

  const toastQueue = [];

  const showToast = (message) => {
    toastQueue.push(message);
    if (!popUpShow) {
      displayNextToast();
    }
  };

  const displayNextToast = () => {
    if (toastQueue.length > 0) {
      const message = toastQueue.shift();
      toast.error(message, {
        autoClose: 3000, // Set the duration as needed
        // onClose: () => {
        //     setPopUpShow(false);
        //     displayNextToast(); // Display the next toast message
        // },
      });
      setPopUpShow(true);
      setTimeout(() => {
        setPopUpShow(false);
        // displayNextToast();
      }, 3500);
    }
  };
  const getcurrentBalance = () => {
    let cData = {
      portfolioId: portFolipID,
      userId: userType?.userId,
    };

    if (cData.userId !== undefined && cData.portfolioId !== undefined) {
      try {
        GetCurrentBalabce(cData).then((res) => {
          if (res?.status === 200) {
            setBrokerData(res.data);
          }
        });
      } catch (error) {}
    }
  };

  const handleRemoveBuySelldata = () => {
    setBuySellTradeData({});
    setBuySellInput({ quantity: "", amount: "" });
  };
  const getExchangeRate = () => {
    if (buySellTradeData?.stockCode !== undefined) {
      let stockPortCountry = portfololioList.filter(
        (val) => val.portfolioId == buySellTradeData.portfolioId
      );
      // let stockCurrency = buySellTradeData?.stockCode.split('_') || ''
      // let stockCountryCode = countryList.filter((val) => val.key.toUpperCase() == buySellTradeData.country.toUpperCase())

      let stockCodeArr = buySellTradeData.stockCode.split("_") || "";

      let newstockCodeArr = [];

      if (
        stockCodeArr[3] === "USDC" ||
        stockCodeArr[3] === "USDT" ||
        stockCodeArr[3] === "USDB"
      ) {
        newstockCodeArr = "USD";
      } else {
        newstockCodeArr = stockCodeArr[3];
      }
      let symbol = CountryCurrencyList.filter(
        (val) => val.code == newstockCodeArr
      );
      setStockCurrencySymbol(symbol[0]?.symbol_native);
      try {
        // getExchangeRateAPI(stockCodeArr[2], newstockCodeArr).then((respons) => {
        getRapidExchangeRateAPI(stockCodeArr[2], newstockCodeArr).then(
          (respons) => {
            const CurrentBidPrice = respons.data.rates?.[newstockCodeArr] || 0;
            const CurrentAskPrice = respons.data.rates?.[newstockCodeArr] || 0;
            setAskPrice(CurrentAskPrice);
            setBidPrice(CurrentBidPrice);
            // const exPrice = res?.data["Realtime Currency Exchange Rate"]?.["5. Exchange Rate"];
            if (
              CurrentAskPrice !== undefined &&
              CurrentBidPrice !== undefined
            ) {
              setBuySellInput({
                ...buySellInput,
                entryPrice:
                  buySellTradeData.short == "buy"
                    ? CurrentAskPrice
                    : CurrentBidPrice,
              });
            } else {
            }
          }
        );
      } catch (error) {}
    }
    //     //  let selectedStockCountry = allCountryList.filter((val) => val.key.toUpperCase() == buySellTradeData.country.toUpperCase())
    //     // console.log(selectedStockCountry)
    //     let stockPortCountry = portfololioList.filter((val) => val.portfolioId == buySellTradeData.portfolioId)
    //     // let stockCurrency = tradeListData[i].stockCode.split('/')
    //     console.log(stockPortCountry, "stockPortCountry")
    //     let selectedStockCountry = "AUD"
    //     try {
    //         GetExchangeRate(selectedStockCountry, buySellTradeData?.portfolio?.currency).then((res) => {
    //             if (res.status == 200) {
    //                 let exchange = res?.data['Realtime Currency Exchange Rate']?.['5. Exchange Rate'] || 1

    //                 setExchangeRate(exchange)
    //             }
    //         })
    //     } catch (error) {

    //     }
    // }

    // let stockCurrency = buySellTradeData
  };
  // useEffect(() => {
  //     getExchangeRate()
  // }, [])

  // const getCurrentPrice = () => {
  //     // if (buySellTradeData?.stockCode) {
  //     //     try {
  //     //         getSearchStockDetails(buySellTradeData?.stockCode).then((res) => {
  //     //             if (res.status == 200) {
  //     //                 let data = res.data
  //     //                 // let aus = buySellTradeData?.stockCode.includes(".AX")
  //     //                 let aus = 'AUD'
  //     //                 if (aus) {
  //     //                     // debugger
  //     //                     let data = res.data
  //     //                     console.log(data, "data")
  //     //                     setStockCurrentData({ price: (data.close).toString(), profit: twoDecimal((data.close - data.open).toString()), profitPer: twoDecimal(((data.close - data.open) * 100 / data.open).toString()) })
  //     //                 } else {
  //     //                     const close = res.data["Global Quote"]["05. price"];
  //     //                     const open = res.data["Global Quote"]["02. open"];
  //     //                     console.log(open, "open ")
  //     //                     setStockCurrentData({ price: (close).toString(), profit: twoDecimal((close - open).toString()), profitPer: twoDecimal(((close - open) * 100 / open).toString()) })
  //     //                 }
  //     //             }
  //     //             console.log(res)

  //     //         })
  //     //     } catch (error) {

  //     //     }
  //     // }
  // }
  useEffect(() => {
    getExchangeRate();
    getcurrentBalance();
    //getCurrentPrice()
  }, [buySellTradeData?.stockCode, portFolipID, userType]);

  const handleBlurInputPort = () => {};

  const handleChangeBuySelloInput = (e) => {
    // debugger;
    // debugger
    if (e.target.name == "quantity") {
      // debugger;
      // debugger
      let vs = Math.round(e.target.value);
      let getask = askprice * vs;
      let getbid = bidprice * vs;
      
      if (
        Number(e.target.value) >= 0 &&
        e.target.value != "" &&
        e.target.value.length <= 10
      ) {
        // debugger;
        let n = e.target.value.split(".");
        let value = twoDecimal(e.target.value);
        let buyamtValRounded = getask;
        let sellamtValRounded = getbid;
        if (n.length == 2) {
          // debugger;
          if (n[0].length <= 10) {
            // debugger;
            setBuySellInput({
              ...buySellInput,
              [e.target.name]: value,
              buyamountvalue: buyamtValRounded,
              sellamountvalue: sellamtValRounded,
            });
          }
        }
        // else if (brokerData?.currentBalance < getask || brokerData?.currentBalance < getbid) {
        //     showToast('Does not have sufficient balance!',)
        // }
        else if (e.target.value.length <= 10) {
          // debugger;
          setBuySellInput({
            ...buySellInput,
            [e.target.name]: value,
            buyamountvalue: buyamtValRounded,
            sellamountvalue: sellamtValRounded,
          });
        }
      } else if (e.target.value == "") {
        // debugger;
        setBuySellInput({
          ...buySellInput,
          [e.target.name]: "",
          buyamountvalue: "",
          sellamountvalue: "",
        });
      }
    } else {
      // debugger
      if (buySellInput?.quantity === '') {
        setBuySellInput({
          ...buySellInput,
          buyamountvalue: "",
          sellamountvalue: "",
        });
      }
      // debugger;
      if (
        Number(e.target.value) >= 0 &&
        e.target.value != "" &&
        e.target.value.length <= 10
      ) {
        let n = e.target.value.split(".");
        let value = twoDecimal(e.target.value);
        if (n.length == 2) {
          // debugger;
          if (n[0].length <= 10) {
            // setBuySellInput({
            //   ...buySellInput,
            //   [e.target.name]: value,
            //   quantity: twoDecimal((value / (price * exchangeRate)).toString()),
            // });
            if (buySellInput?.quantity === "") {
              setBuySellInput({
                ...buySellInput,
                buyamountvalue: "",
                sellamountvalue: "",
              });
            }
          }
        } else if (e.target.value.length <= 10) {
          // debugger;
          if (buySellInput?.quantity === "") {
            setBuySellInput({
              ...buySellInput,
              buyamountvalue: "",
              sellamountvalue: "",
            });
          }
          // setBuySellInput({
          //   ...buySellInput,
          //   [e.target.name]: value,
          //   quantity: twoDecimal((value / (price * exchangeRate)).toString()),
          // });
        }
      } else if (e.target.value == "") {
        // debugger;
        if (buySellInput?.quantity === "") {
          setBuySellInput({
            ...buySellInput,
            buyamountvalue: "",
            sellamountvalue: "",
          });
        }
        // setBuySellInput({ ...buySellInput, [e.target.name]: "", quantity: "" });
      }
    }
  };
  // console.log(buySellTradeData ,"buySellTradeData")
  const handleSubmitBuySell = (type) => {
    // debugger
    let stockPriceValue =
      type === "buy"
        ? buySellInput.buyamountvalue
        : buySellInput.sellamountvalue;
    let getcalculatedask = askprice * buySellInput?.quantity;
    // setCalculatedAsk(getcalculatedask)

    let value = {
      TradeId: buySellTradeData?.transactionNo,
      Qty: buySellInput?.quantity,
      StockPrice: twoDecimal(stockPriceValue),
      Short: type,
    };
    try {
      if (type === "buy") {
        // Check if the current balance is sufficient for buying
        if (brokerData?.currentBalance < getcalculatedask) {
          showToast("Does not have sufficient balance!");
          return; // Stop further execution
        }
      } else if (type === "sell") {
        // Check if the quantity is not greater than available quantity for selling
        if (buySellInput?.quantity > buySellTradeData?.quantity) {
          showToast("Does not have sufficient Crypto!");
          return; // Stop further execution
        }
      }

      modifyOpenTrade(value).then((res) => {
        if (res?.status === 200) {
          handleGetOpenTradelist("openTrade");
          document.getElementById("closeBuySell").click();
        }
      });
    } catch (error) {}
  };

  return (
    <div
      className="modal fade show backdrop-1"
      data-bs-backdrop="static"
      id="BuySellUpdateTrade"
      tabindex="-1"
      aria-labelledby="exampleModalLgLabel"
      style={{ display: "none" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg  modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header p-0 border-bottom-0">
            <h5
              className="modal-title h4 buySell_main"
              id="exampleModalLgLabel"
            >
              {buySellTradeData?.stockCode &&
                buySellTradeData.stockCode.split("_").length >= 4 &&
                `${buySellTradeData.stockCode.split("_")[2]}_${
                  buySellTradeData.stockCode.split("_")[3]
                }`}
              <span className="buysellspan"> Ask </span>
              <span>
                {!isNaN(parseFloat(askprice)) && isFinite(askprice)
                  ? parseFloat(askprice).toFixed(5)
                  : "0"}
              </span>{" "}
              <span className="buysellspan">Bid</span>{" "}
              <span>
                {!isNaN(parseFloat(bidprice)) && isFinite(bidprice)
                  ? parseFloat(bidprice).toFixed(5)
                  : "0"}
              </span>{" "}
            </h5>

            <button
              type="button"
              className="btn-close btn-close-provider"
              id="closeBuySell"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleRemoveBuySelldata}
            >
              ×
            </button>
          </div>
          <div className="col-lg-12">
            <Formik
              initialValues={buySellInput}
              enableReinitialize={true}
              validationSchema={validationSchemaBuy}
              onSubmit={(value) => {
                // handleSubmitBuySell()
              }}
            >
              {({ errors, touched }) => (
                <Form className="row p-3">
                  {/* <div class="col-sm-12"> */}
                  <div class="col-6">
                    <label>
                      Total Crypto<span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      value={buySellTradeData?.quantity || 0}
                      disabled
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="portfolioName"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                    />
                    {errors.portfolioName && touched.portfolioName ? (
                      <div className="color-msg-err">
                        {errors.portfolioName}
                      </div>
                    ) : null}
                  </div>
                  <div class="col-6">
                    <label>
                      Total Amount <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      value={twoDecimal(
                        (
                          (buySellTradeData?.profitLoss || 0) +
                          (buySellTradeData?.valueInShares || 0)
                        ).toString()
                      )}
                      disabled
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="portfolioName"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                    />
                    {errors.portfolioName && touched.portfolioName ? (
                      <div className="color-msg-err">
                        {errors.portfolioName}
                      </div>
                    ) : null}
                  </div>
                  <div class="col-6 mt-2">
                    <label>
                      Crypto <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="quantity"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                    />
                    {errors.quantity && touched.quantity ? (
                      <div className="color-msg-err">{errors.quantity}</div>
                    ) : null}
                  </div>
                  <div class="col-6 mt-2">
                    <label>
                      Buy Amount <span>{stockcurrencysymbol}</span>{" "}
                      <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="amount"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                      value={buySellInput.buyamountvalue}
                    />
                    {/* {errors.amount && touched.amount ? (
                                            <div className='color-msg-err'>{errors.amount}</div>
                                        ) : null} */}
                  </div>
                  <div class="col-6 mt-2">
                    <label>
                      Sell Amount <span>{stockcurrencysymbol}</span>{" "}
                      <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="amount"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                      value={buySellInput.sellamountvalue}
                    />
                    {/* {errors.amount && touched.amount ? (
                                            <div className='color-msg-err'>{errors.amount}</div>
                                        ) : null} */}
                  </div>
                  <div class="col-6 mt-2"></div>
                  <div className="col-md-4 d-flex">
                    {/* <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit' >{showEdit === true ? 'Edit Porfolio' : <>Add Porfolio <span> <i class="bi bi-plus-lg"></i> </span></>}   <span>  </span></button> */}

                    <button
                      class="btn buyButton mt-3 w-100"
                      style={{ padding: "2px 37px" }}
                      onClick={() => handleSubmitBuySell("buy")}
                      type="submit"
                    >
                      Buy
                    </button>
                    <button
                      class="btn ms-md-2 ms-2  mt-3 w-100 sellButton"
                      style={{ padding: "2px 37px" }}
                      onClick={() => handleSubmitBuySell("sell")}
                      id="Cancle-portFolio"
                      type="submit"
                    >
                      Sell
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <div className="modal-body blackout-bx">

                        <div class="table-responsive">
                            <table class="table table-input1 table-bordered mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col" className='color-grey'>Date</th>
                                        <th scope="col" className='color-grey'>Price</th>
                                        <th scope="col" className='color-grey'>Quantity</th>
                                        <th scope="col" className='color-grey'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default BuySellUpdateTrade;
