import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}

export const getCountryListAPI = async (id) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetCountry`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getExchangeRateAPI = async (first,second) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Crypto/GetExchangeRate/${first+'/'+second}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if(response?.status===200){
            return response
        }
        else if(response?.status===401){
            alert('Not authorized.')
        }else if(response?.status>=500){
            
        }
        // 
    } catch (error) {
    }
}


export const getRapidExchangeRateAPI = async (first, second) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Rapid/GetExchangeRate/${first + "/" + second}`,
      mode: "cors",
      headers,
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
    }
    //
  } catch (error) {}
};