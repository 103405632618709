import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormik } from 'formik'
import * as Yup from "yup";
import { createStrategyAPI, deleteStrategy, editStrategy, getStrategy, getTradeSystemBYID } from '../../../services/StrategyService'
import AddEditStrategy from '../../Admin/Model/AddEditStrategy';
import DeleteConfirmation from '../../commonComponent/DeleteConfirmation';
import Pagination from '../../commonComponent/Pagination';


const Strategy = ({ userType }) => {

    const [strategyInput, setSerategyInput] = useState({ UserId: userType.role=='user'? userType?.userId: 0, Name: '', Notes: '', EntryStrategy: '', ExitStrategy: '', ScaleInOutStrategy: '',reRender:0 })
    const { UserId, Name, EntryStrategy, ExitStrategy, ScaleInOutStrategy } = strategyInput
    const [runAgainStrategyAPI, setRunAgainStrategyAPI] = useState(true)
    const [strategyList, setStrategyList] = useState([])
    const [runAgainAPI, setRunAgainbAPI] = useState(true)
    const [tranTypeList, setTranTypeList] = useState([])
    const [editTrue, setEditTrue] = useState(false)
    const [addUpdateSelect, setAddUpdateSelect] = useState('')
    const [deleteConfirmData, setDeleteConfirmData] = useState('')
    const [deleteMsg, setDeleteMsg] = useState('')
    const [pagination, setPagination] = useState({ currentPage: 1, pageNumber: 1, pageSize: 10, totalPages: 0, search: '' })
    const { pageNumber, pageSize, currentPage, totalPages, search } = pagination

    useEffect(() => {
        if (userType?.userId !== undefined && runAgainStrategyAPI==true) {
            let data={
                id:userType?.userId,
                pageNumber:currentPage,
                pageSize:pageSize
            }
            try {
                getTradeSystemBYID(data).then((res) => {
                    if (res?.status === 200) {
                 
                        
                        setRunAgainbAPI(false)
                        setStrategyList(res.data.data)
                        if(res.data.data.length==0&&currentPage>1){
                            setRunAgainStrategyAPI(true)
                            setPagination({ ...pagination, totalPages: res.data.totalPages, currentPage:1,pageNumber:1 })
                        }else{
                            setRunAgainStrategyAPI(false)
                            setPagination({ ...pagination, totalPages: res.data.totalPages })
                        }
                        // totalPages
                    }
                })
            } catch (error) {

            }
        }
    }, [runAgainStrategyAPI, userType,currentPage])
    // const formik=useFormik()
   
    const handlePageChange = (newPage) => {
        setPagination({ ...pagination, currentPage: currentPage + newPage })
        setRunAgainStrategyAPI(true)
        // Perform data fetching or other actions when page changes
    };

    const handleEditStrategy = (value) => {
        setEditTrue(true)
        setSerategyInput({ UserId: userType?.userId, id: value.tradingSystemId, Name: value.name, Notes: value.notes, EntryStrategy: value.entryStrategy, ExitStrategy: value.exitStrategy, ScaleInOutStrategy: value.scaleInOutStrategy })
    }

    const handleAddStrategy = () => {
        // setInputData({ providerName: '', parentCompany: '', yearAsCFDProvider: '', platform: '' })
        setAddUpdateSelect('Add')
        setEditTrue(false)
    }

    const handleDeleteTredingSystem = (val) => {
        setDeleteMsg('delete this Trading System')
        setDeleteConfirmData(val)
    }

    const deleteTradeSystem = () => {

        try {
            deleteStrategy(deleteConfirmData).then((res) => {

                if (res?.status === 200) {
                    setRunAgainStrategyAPI(true)
                    setDeleteConfirmData('')
                    
                }
            })
        } catch (error) {

        }
    }

    const deleteConfirm = () => {
        if (deleteConfirmData !== '' && deleteConfirmData !== undefined) {
            deleteTradeSystem()
        }
    }
    return (
        <>
            <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="createStrategy" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg modal-fixed-lg  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Strategy</h5>
                            <button type="reset" className="btn-close btn-close-provider"  data-bs-dismiss="modal" aria-label="Close">×</button>
                        </div>
                        <div className="modal-body">
                          
                            <div class="row">
                                <div className="col-lg-4 ms-auto ">
                                    <button class="btn  ms-auto d-block " onClick={handleAddStrategy} data-bs-toggle="modal" data-bs-target="#addEditStrategy">Add Strategy <span> <i class="bi bi-plus-lg"></i> </span></button>
                                </div>
                                <div class="col-sm-12">
                                    <div class="postion-bx-min mt-2 col-sm-6">
                                        <span class="frm-hd-t">Trading System</span>

                                    </div>

                                    <div class="row mt-1">
                                        <div class="col-sm-12">
                                            <div class="postion-min-dta postion-frm">

                                                <div class="table-responsive ">
                                                    <div class="table-input1 text-center">
                                                        <table class="table text-center  table-input-data1">
                                                            <thead>
                                                                <tr class="border-bottom-0">
                                                                    <th scope="row" class="ad-tab-b color-grey ">Name</th>
                                                                    <th scope="row" class="ad-tab-b color-grey ">Entry Strategy </th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Exit Strategy</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Scale In/Out Strategy</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Comments</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Edit</th>
                                                                    <th scope="row" class="ad-tab-b color-grey">Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    strategyList?.map((val, ind) => {
                                                                        return (
                                                                            <tr key={ind} className='white_text' >
                                                                                <th> &nbsp;{val.name} </th>
                                                                                <td> &nbsp;{val.entryStrategy} </td>
                                                                                <td> &nbsp;{val.exitStrategy} </td>
                                                                                <td> &nbsp;{val.scaleInOutStrategy} </td>
                                                                                <td> &nbsp;{val.notes} </td>
                                                                                <td onClick={() => handleEditStrategy(val)}>
                                                                                    <button class="btn padding-btn-dtd edit-btn-adm" data-bs-toggle="modal" data-bs-target="#addEditStrategy" title="Edit">
                                                                                        <img src="assets/image/edit1.png" class="img-fluid" />  </button>
                                                                                </td>
                                                                                <td><button class="btn padding-btn-dtd delete-admin" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteModelstr" onClick={() => handleDeleteTredingSystem(val)}> <i class="bi bi-trash3-fill"></i> </button></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                        <Pagination
                                                            currentPage={currentPage}
                                                            totalPages={totalPages}
                                                            onPageChange={handlePageChange}
                                                            setPagination={setPagination}
                                                            pageNumber={pageNumber}
                                                            pageSize={pageSize}
                                                            pagination={pagination}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show backdrop" data-bs-backdrop="static" id="deleteModelstr" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ 'display': 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0 ps-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Are you sure?</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" aria-label="Close">×</button>
                        </div>
                        <div className='col-12'>
                            <p className='white_text' >
                                Do you really want to {deleteMsg} ? This process cannot be undone.
                            </p>
                        </div>
                        <div className='row'>
                        <div className='col-md-6'>
                            <button class="btn  ms-1 d-block mt-3 w-100" type='submit' data-bs-dismiss="modal" aria-label="Close">Cancel </button>
                        </div>
                        <div className='col-md-6'>
                            <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-provider' type='button' onClick={() => deleteConfirm()} data-bs-dismiss="modal" aria-label="Close" >Ok</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddEditStrategy userType={userType} setRunAgainStrategyAPI={setRunAgainStrategyAPI} setSerategyInput={setSerategyInput} strategyInput={strategyInput} editTrue={editTrue} />
        </>
    )
}

export default Strategy