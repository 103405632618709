import { BiChevronUp } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { FaChartArea } from "react-icons/fa";
import { HiPresentationChartLine } from "react-icons/hi";
import { BsFillPieChartFill } from "react-icons/bs";
import { GiCutDiamond } from "react-icons/gi";
import { DiGoogleAnalytics } from "react-icons/di";
import { DiResponsive } from "react-icons/di";
import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import Pagination from "../commonComponent/Pagination";
import { getPortfolioReturn, getTransactionListFinancialAPI } from "../../services/ManageAccountServices";
import { getSearchStockDetails } from "../../services/HomeService";
import { GetInvestmentReturnMonthly, GetInvestmentReturnWeekly, getOpentradeList, getdepositwithdrawl, getOpentradeListFinancelYear } from "../../services/TradeService";
import { BiUpArrow, BiDownArrow } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { getExchangeRateAPI } from "../../services/commonServices";
import { object } from "yup";

const PortfolioTable = ({
    runAgainDashBoard,
    setGetOpenToggleId,
    userType,
    setOpenTradeRun,
    selectedUserData,
}) => {
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageNumber: 1,
        pageSize: 10,
        totalPages: 0,
    });
    const navigate = useNavigate()
    const { pageNumber, pageSize, currentPage, totalPages } = pagination; // Total number of pages in your data set
    const [transactionList, setTransactionList] = useState([]);
    const [openTradeList, setOpenTradeList] = useState([]);
    const [sharperatio, setSharpeRatio] = useState('')
    const [tradereturnvalue, setTradeReturnValue] = useState({
        initalvalue: 0,
        currentvalue: 0
    })
    const [withdrawl, setWithdrawl] = useState([])
    const [deposit, setDeposit] = useState([])
    const [portfolioreturn, setPortfolioReturn] = useState('')
    const [startingBalance, setstartingBalance] = useState([])
    const [inflationrate, setInflationRate] = useState('')
    const [standardDeviation, setStandardDeviation] = useState('')
    const [paginationTrade, setPaginationTrade] = useState({
        currentPageTrade: 1,
        pageNumberTrade: 1,
        pageSizeTrade: 10,
        totalPagesTrade: 0,
    });
    const { currentPageTrade, pageNumberTrade, pageSizeTrade, totalPagesTrade } =
        paginationTrade;
    const [showHideTran, setShowHideTran] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState({
        symbol: "",
        code: "",
    });

    let symbol;
    if(localStorage.getItem("currencySymbol")){
         symbol = JSON.parse(localStorage.getItem("currencySymbol"));
    }
   
    useEffect(() => {
        if(typeof symbol === 'object')
            setCurrencySymbol(symbol);
    }, [symbol?.code]);
    

    const handlePageChange = (newPage) => {
        setPagination({ ...pagination, currentPage: currentPage + newPage });
        // Perform data fetching or other actions when page changes
    };
    const handlePageChangeTrade = (newPage) => {
        setPaginationTrade({
            ...paginationTrade,
            currentPageTrade: currentPageTrade + newPage,
        });
        // Perform data fetching or other actions when page changes
    };
    useEffect(() => {
        let nm = document.getElementById("open-Trade-click");
        setGetOpenToggleId(nm);
    }, []);
    useEffect(() => {
        let data = {
            providerId: selectedUserData.providerId,
            portfolioId: selectedUserData.portFolioId,
            id: userType?.userId,
            pageNumber: currentPageTrade,
            pageSize: pageSizeTrade,
            dateFrom: selectedUserData.dateFrom,
            dateTo: selectedUserData.dateTo,
        };
        if (data.id !== undefined && data.portfolioId !== undefined) {
            try {
                getOpentradeListFinancelYear(data).then((res) => {
                    if (res?.status === 200) {
                        setOpenTradeList(res.data.data);
                        setPaginationTrade({
                            ...paginationTrade,
                            totalPagesTrade: res.data.totalPages,
                        });
                        let data = res.data;
                        // let pft = 0
                        // let bs = 0
                        // let bcfd = 0, lev

                        // lev = (bcfd / bs) * 100
                        // setShowData({ ...showData, profit: pft.toFixed(2), balanceinShares: bs, balanceinCFD: bcfd, leverage: lev })
                    } else {
                        // setShowData({ ...showData, balanceinShares: 0, balanceinCFD: 0 })
                    }
                });
            } catch (error) { }
        }
    }, [
        currentPageTrade,
        userType,
        selectedUserData.portFolioId,
        runAgainDashBoard,
        selectedUserData.dateFrom,
        selectedUserData.dateTo,
    ]);

    useEffect(() => {

        let paramData = {
            PortfolioId: selectedUserData.portFolioId,
            id: userType?.userId,
            pageNumber: currentPage,
            pageSize: pageSize,
            dateFrom: selectedUserData.dateFrom,
            dateTo: selectedUserData.dateTo,
        };

        if (paramData.id !== undefined && paramData.PortfolioId !== undefined) {
            try {
                getTransactionListFinancialAPI(paramData).then((res) => {
                    if (res?.status === 200) {
                        setTransactionList(res.data.data);
                        setPagination({ ...pagination, totalPages: res.data.totalPages });
                    }
                });
            } catch (error) { }
        }
    }, [
        currentPage,
        userType,
        selectedUserData.portFolioId,
        selectedUserData.dateFrom,
        selectedUserData.dateTo,
        runAgainDashBoard,
        selectedUserData,
    ]);

    // .................portfolio performance


    // useEffect(() => {
    //     getopentrade()
    //     depositwithdrawl()
    // }, [])


    useEffect(() => {
        
        depositwithdrawl()
        
    }, [selectedUserData.portFolioId, userType])


    const depositwithdrawl = () => {
        // debugger
        let cData = {
            portfolioId: selectedUserData.portFolioId,
            UserId: userType?.userId,
        }

      
        if (cData.UserId !== undefined && cData.portfolioId !== undefined) {
            try {
                getdepositwithdrawl(cData).then((res) => {
                    if (res?.status === 200) {
                     
                        setDeposit(res.data.totalDeposit)
                        setWithdrawl(res.data.totalWithdrawal)

                    }
                   
                })
            } catch (error) {

            }
        }
        else{
            setDeposit(0)
            setWithdrawl(0)
        }
    }
    // const PortfolioReturn = () => {

    //     let cData = {
    //         PortfolioId: selectedUserData.portFolioId,
    //         UserId: userType?.userId,
    //     }

     
    //     if (cData.UserId !== undefined && cData.PortfolioId !== undefined) {
    //         try {
    //             getPortfolioReturn(cData).then((res) => {
                   
    //                 if (res?.status === 200) {
    //                     // setBrokerData(res.data)
    //                     setstartingBalance(res.data.startingBalance)
    //                 }
    //             })
    //         } catch (error) {

    //         }
    //     }
    // }

    // const getopentrade = () => {
    //     let cData = {
    //         portfolioId: selectedUserData.portFolioId,
    //         pageNumber: 1,
    //         pageSize: 500,
    //         id: userType?.userId,
    //     }
    //     if(cData?.portfolioId !== undefined && cData?.id !== undefined){
    //     try {
    //         getOpentradeList(cData).then((res) => {
    //             if (res?.status === 200) {
    //                 const transactions = res.data.data;
                   

    //                 const opendate = transactions[0]?.openDate

    //                 // const splitOpenDate = opendate?.split('T')
    //                 // const getopendate = splitOpenDate[0]
    //                 if (opendate) {
    //                     const splitOpenDate = opendate?.split('T')
    //                     const getopendate = splitOpenDate[0]
                       
    //                     const stockNamesArray = transactions.map((e) => e.stockCode);
    //                     addProfitLoass(res.data.data, stockNamesArray, getopendate)
                       

    //                 }
    //                 else {
                       
    //                 }
    //             }
    //         })
    //     } catch (error) {
    //     }
    //     }
    // }

    // let Returnvalue = {
    //     initalvalue: 0,
    //     currentvalue: 0
    // }
    // const addProfitLoass = (tradeListData, stockNamesArray, getopendate) => {
      
    //     let pdata = []
    //     if (tradeListData != undefined && tradeListData.length > 0) {

    //         for (let i = 0; i < tradeListData.length; i++) {
    //             if (tradeListData[i].short !== '') {


    //                 let stockCodeArr = tradeListData[i].stockCode.split('_')
    //                 let newstockCodeArr = []
    //                 if (stockCodeArr[3] == 'USDC' || stockCodeArr[3] == 'USDB' || stockCodeArr[3] == 'USDT') {
    //                     newstockCodeArr = 'USD'
    //                 }
    //                 else {
    //                     newstockCodeArr = stockCodeArr[3]
    //                 }
    //                 //  let symbol = CountryCurrencyList.filter((val) => val.code == newstockCodeArr)

    //                 try {
                     
    //                     getExchangeRateAPI(stockCodeArr[2], newstockCodeArr).then((respons) => {
                         
    //                         try {
    //                             getExchangeRateAPI(newstockCodeArr, tradeListData[i]?.portfolio.currency).then((res) => {
                                    
                                   
    //                                 const exPrice = res.data["Realtime Currency Exchange Rate"]["5. Exchange Rate"];
    //                                 const bidPrice = respons.data["Realtime Currency Exchange Rate"]["8. Bid Price"];
    //                                 const askPrice = respons.data["Realtime Currency Exchange Rate"]["9. Ask Price"];
                                   
    //                                 if (tradeListData[i].short == 'buy' || tradeListData[i].short == 'long') {




    //                                     let plValue = (((askPrice * exPrice) * tradeListData[i]?.quantity || 0) - tradeListData[i]?.valueInShares)
    //                                     pdata.push(tradeListData[i]?.valueInShares)
    //                                     Returnvalue = {
    //                                         initalvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.initalvalue),
    //                                         currentvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.currentvalue) + Number(plValue)
    //                                     }
                                      

    //                                 } else if (tradeListData[i].short == 'sell' || tradeListData[i].short == 'short') {
                                     
    //                                     pdata.push(tradeListData[i]?.valueInShares)
    //                                     let openTradeListing = tradeListData[i]
    //                                     let plValue = (tradeListData[i]?.valueInShares - ((bidPrice * exPrice) * tradeListData[i]?.quantity || 0))
    //                                     Returnvalue = {
    //                                         initalvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.initalvalue),
    //                                         currentvalue: Number(tradeListData[i]?.valueInShares) + Number(Returnvalue.currentvalue) + Number(plValue)
    //                                     }
                                       
    //                                     setTradeReturnValue({ ...Returnvalue })
    //                                     openTradeListing.profitLoss = Number(plValue).toFixed(3)
    //                                     openTradeListing.stockName = stockCodeArr[2]
    //                                     openTradeListing.cPrice = Number(bidPrice).toFixed(3)

    //                                 }
                                    
    //                                 if (pdata.length == tradeListData.length) {

    //                                     InvestmentReturn(stockNamesArray, getopendate, Returnvalue, pdata)
    //                                 }
    //                             })
    //                         } catch (error) {

    //                         }


    //                     })
    //                 } catch (error) {

    //                 }


    //             }
    //         }
    //     } else {

    //     }

    //     // setOpen

    // }



    // const InvestmentReturn = (stockNamesArray, getopendate, Returnvalue, pdata) => {
        
       
    //     try {
    //         stockNamesArray.forEach((currencyPair) => {
    //             const currencies = currencyPair.split('_');
            
    //         let newcurrencies = []
    //         if (currencies[3] === 'USDC' || currencies[3] === 'USDT' || currencies[3] === 'USDB') {
    //             newcurrencies = 'USD';
    //         }
    //         else {
    //             newcurrencies = currencies[currencies.length-1]
    //         }

              
    //             currencies.forEach((currency) => {

    //                 let fromCurrency = currencies[2]
    //                 let toCurrency = newcurrencies

                   
    //                 const currentDate = new Date();
    //                 const providedDateTime = new Date(getopendate);
    //                 const differenceInMilliseconds = currentDate - providedDateTime;
    //                 const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
                   
    //                 if (differenceInDays <= 30) {
    //                     GetInvestmentReturnWeekly({ CurrencyFrom: fromCurrency, CurrencyTo: toCurrency }).then((res) => {
    //                         // debugger

    //                         const currentDate = providedDateTime;
    //                         // const EndDate = new Date('2022-12-30');
    //                         let startingDate = new Date(currentDate);
    //                         startingDate.setDate(startingDate.getDate() - 28); // Go back 4 weeks from the ending date
    //                         // let endDate =startingDate.setDate(startingDate.getDate() - 28);


    //                         let EndingDateString = currentDate.toISOString().split('T')[0];
    //                         // const startingDateString = EndDate.toISOString().split('T')[0];
    //                         let startingDateString = startingDate.toISOString().split('T')[0];


    //                         if (res?.status == 200) {
    //                             const timeSeries =res?.data['Time Series (Digital Currency Weekly)']



    //                             if (timeSeries) {
    //                                 // const currentDateForAPI = new Date(providedDateTime);

    //                                 let EndingClosePrice = timeSeries[EndingDateString];
    //                                 let StartingClosePrice = timeSeries[startingDateString];

    //                                 while (!EndingClosePrice && currentDate >= providedDateTime) {
    //                                     currentDate.setDate(currentDate.getDate() + 1);
    //                                     const nextDate = currentDate.toISOString().split('T')[0];
    //                                     EndingClosePrice = timeSeries[nextDate];
    //                                     if (EndingClosePrice) {
    //                                         EndingDateString = nextDate; // Update the EndingDateString when found
    //                                     }
    //                                 }

    //                                 // If data for the exact starting date is not available, find the nearest previous date with data
    //                                 while (!StartingClosePrice && startingDate >= startingDate) {
    //                                     startingDate.setDate(startingDate.getDate() - 1);
    //                                     const previousDate = startingDate.toISOString().split('T')[0];
    //                                     StartingClosePrice = timeSeries[previousDate];

    //                                     if (StartingClosePrice) {
    //                                         startingDateString = previousDate;
    //                                     }
    //                                 }

    //                                 const filteredData = {};
    //                                 // const currentDate = new Date();
    //                                 // const providedDateTime = new Date(getopendate);

    //                                 for (const date in timeSeries) {
    //                                     //   const currentDateISO = currentDate.toISOString().split('T')[0];
    //                                     //   const providedDateTimeISO = providedDateTime.toISOString().split('T')[0];

    //                                     if (date >= startingDateString && date <= EndingDateString) {
    //                                         filteredData[date] = timeSeries[date];
    //                                     }
    //                                 }


    //                                 const datesArray = Object.keys(filteredData);
    //                                 datesArray.sort();
    //                                 const priceDifferences = [];
    //                                 const datePairs = [];
    //                                 const pricepercentage = [];
    //                                 for (let i = 0; i < datesArray.length - 1; i++) {
    //                                     debugger
    //                                     let replacecurrency =newcurrencies.replace(`"`)
    //                                     // const currencyCode = 'KRW';
    //                                     const currentWeekClose = parseFloat(filteredData[datesArray[i]][`4a. close (${replacecurrency})`]);
    //                                     const nextWeekClose = parseFloat(filteredData[datesArray[i + 1]][`4a. close (${replacecurrency})`]);
    //                                     const difference = nextWeekClose - currentWeekClose;
    //                                     // let  deff={
    //                                     //         diff:difference,
    //                                     //         pdiff:
    //                                     // //     }


    //                                     // priceDifferences.push(deff);
    //                                     priceDifferences.push(difference);
    //                                     pricepercentage.push((filteredData[datesArray[i + 1]][`1a. open (${newcurrencies})`] * difference) / 100)
    //                                         ;

    //                                     const pair = `${datesArray[i + 1]} - ${datesArray[i]}`;
    //                                     datePairs.push(pair);
    //                                 }



    //                                 let total = 0;
    //                                 for (let i = 0; i < pricepercentage.length; i++) {
    //                                     total += pricepercentage[i];
    //                                 }

    //                                 const average = total / pricepercentage.length;
    //                                 let x = 0
    //                                 for (let i = 0; i < pricepercentage.length; i++) {
    //                                     let n = ((pricepercentage[i] - average) ** 2) / pricepercentage.length
    //                                     x += n
    //                                 }
    //                                 setStandardDeviation(x)
    //                                 portfolioPerformance(Returnvalue, x)
                                   
    //                                 if (EndingClosePrice && StartingClosePrice) {
    //                                     const closeEndPrice = EndingClosePrice['4a. close (newcurrencies)'];
    //                                     const closeStartPrice = StartingClosePrice['4a. close (newcurrencies)'];
                                        

    //                                     //   let returnofinvestment =
    //                                 } else {
    //                                     // console.log("Error: No data found for the specified starting date.");
    //                                 }
    //                             } else {
    //                                 // console.log("Error: No time series data available.");
    //                             }

    //                         }
    //                     }).catch((error) => {
    //                         console.error(`Error fetching weekly data for ${fromCurrency}/${toCurrency}:`, error);
    //                     });
    //                 }
    //                 else {
    //                     GetInvestmentReturnMonthly({ CurrencyFrom: fromCurrency, CurrencyTo: toCurrency }).then((res) => {
    //                         // debugger

    //                         const currentDate = providedDateTime;
    //                         // const EndDate = new Date('2022-12-30');
    //                         let startingDate = new Date(currentDate);
    //                         startingDate.setDate(startingDate.getDate() - 28); // Go back 4 weeks from the ending date
    //                         // let endDate =startingDate.setDate(startingDate.getDate() - 28);


    //                         let EndingDateString = currentDate.toISOString().split('T')[0];
    //                         // const startingDateString = EndDate.toISOString().split('T')[0];
    //                         let startingDateString = startingDate.toISOString().split('T')[0];



    //                         if (res?.status === 200) {
    //                             const timeSeries = res?.data['Digital Currency Weekly'];



    //                             if (timeSeries) {
    //                                 // const currentDateForAPI = new Date(providedDateTime);

    //                                 let EndingClosePrice = timeSeries[EndingDateString];
    //                                 let StartingClosePrice = timeSeries[startingDateString];

    //                                 while (!EndingClosePrice && currentDate >= providedDateTime) {
    //                                     currentDate.setDate(currentDate.getDate() + 1);
    //                                     const nextDate = currentDate.toISOString().split('T')[0];
    //                                     // console.log(nextDate ,"nextDate")
    //                                     EndingClosePrice = timeSeries[nextDate];
    //                                     if (EndingClosePrice) {
    //                                         EndingDateString = nextDate; // Update the EndingDateString when found
    //                                         // console.log(EndingDateString ,"EndingDateString whole")
    //                                     }
    //                                 }

    //                                 // If data for the exact starting date is not available, find the nearest previous date with data
    //                                 while (!StartingClosePrice && startingDate >= startingDate) {
    //                                     //   console.log(StartingClosePrice ,"StartingClosePrice")
    //                                     startingDate.setDate(startingDate.getDate() - 1);
    //                                     const previousDate = startingDate.toISOString().split('T')[0];
    //                                     StartingClosePrice = timeSeries[previousDate];

    //                                     if (StartingClosePrice) {
    //                                         startingDateString = previousDate;
    //                                     }
    //                                 }

    //                                 const filteredData = {};


    //                                 for (const date in timeSeries) {


    //                                     if (date >= startingDateString && date <= EndingDateString) {
    //                                         filteredData[date] = timeSeries[date];
    //                                     }
    //                                 }


    //                                 const datesArray = Object.keys(filteredData);
    //                                 datesArray.sort();
    //                                 const priceDifferences = [];
    //                                 const datePairs = [];
    //                                 const pricepercentage = [];
    //                                 for (let i = 0; i < datesArray.length - 1; i++) {

    //                                     const currentWeekClose = parseFloat(filteredData[datesArray[i]]['4a. close (CNY)']);
    //                                     const nextWeekClose = parseFloat(filteredData[datesArray[i + 1]]['4a. close (CNY)']);

    //                                     const difference = nextWeekClose - currentWeekClose;


    //                                     priceDifferences.push(difference);
    //                                     pricepercentage.push((filteredData[datesArray[i + 1]]['1. open'] * difference) / 100)
    //                                         ;

    //                                     const pair = `${datesArray[i + 1]} - ${datesArray[i]}`;
    //                                     datePairs.push(pair);
    //                                 }



    //                                 let total = 0;
    //                                 for (let i = 0; i < pricepercentage.length; i++) {
    //                                     total += pricepercentage[i];
    //                                 }

    //                                 const average = total / pricepercentage.length;
    //                                 let x = 0
    //                                 for (let i = 0; i < pricepercentage.length; i++) {
    //                                     let n = ((pricepercentage[i] - average) ** 2) / pricepercentage.length
    //                                     x += n
    //                                 }
    //                                 setStandardDeviation(x)
    //                                 portfolioPerformance(Returnvalue, x)

    //                                 if (EndingClosePrice && StartingClosePrice) {
    //                                     const closeEndPrice = EndingClosePrice['4. close'];
    //                                     const closeStartPrice = StartingClosePrice['4. close'];
    //                                     // console.log(closeStartPrice, closeEndPrice, "closeStartPrice ,closeEndPrice");

    //                                     //   let returnofinvestment =
    //                                 } else {
    //                                     // console.log("Error: No data found for the specified starting date.");
    //                                 }
    //                             } else {
    //                                 // console.log("Error: No time series data available.");
    //                             }

    //                         }
    //                     }).catch((error) => {
    //                         console.error(`Error fetching weekly data for ${fromCurrency}/${toCurrency}:`, error);
    //                     });
    //                 }





    //             });
    //         });
    //     } catch (error) {
    //         console.error('Error in InvestmentReturn function:', error);
    //     }
    // };

    // const portfolioPerformance = (Returnvalue, x) => {
    //     // debugger

    //     let pfReturn = Number((Returnvalue.currentvalue - Returnvalue.initalvalue) / Returnvalue.initalvalue * 100);

    //     setPortfolioReturn(pfReturn)
    //     const nominalRate = 0.02;
    //     const inflationRate = Number(inflationrate);
    //     const riskFreeRate = Number(Number(1 + nominalRate) / Number(1 + inflationRate));

    //     // let performance =Number(( portfolioreturn -  riskFreeRate)/standardDeviation)
    //     let performance = Number((portfolioreturn - riskFreeRate))
       
    //     // console.log(riskFreeRate ,"riskFreeRate")
      
    //     const std = (x.toString()).split('e')[0]
    //     let perfdivide = Number(performance / (Number(std)))

       
    //     const Ratio = (perfdivide).toFixed(2)
    //     setSharpeRatio(Ratio)
    //     //  let category = '';


    //     //  if (Ratio >= 1 && Ratio < 2) {
    //     //      category = 'Good';
    //     //      textClass = 'greenText'; // Assigning the class based on the condition
    //     //  } else if (Ratio >= 2 && Ratio < 3) {
    //     //      category = 'Very Good';
    //     //      textClass = 'greenText';
    //     //  } else if (Ratio < 1) {
    //     //      category = 'Bad';
    //     //      textClass = 'redText';
    //     //  }

    // }


    const handleNavigaterecords = () => {
      
        navigate('/Records', {
            state: {
                userType,
                selectedUserData,
                currencySymbol,
                pageSize,
                currentPage,
            },
        });
    };
    return (
        <>
            <div className="Portfolio-tablesec">
                {/* <div className="row mt-4">
                    <div className="col-lg-6 col-sm-5">
                        <Link to={"/Crypto-screener"}>
                            <button className="btn bt-drk admin-btn-main" type="button">
                                Crypto Screener
                            </button>
                        </Link>
                    </div>
                    <div className="col-lg-6 col-sm-5 ms-auto d-block text-end">
                        {userType.role === "user" ? (
                            <h6
                                className="btn bt-drk admin-btn-main"
                                data-bs-toggle="modal"
                                data-bs-target="#createStrategy"
                            >
                                Strategy{" "}
                            </h6>
                        ) : (
                            <Link to={"/admin"}>
                                <button className="btn bt-drk admin-btn-main" type="button">
                                    Admin
                                </button>
                            </Link>
                        )}
                    </div>
                </div> */}
                <section class="discount_add mt-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-xl-4">
                                <div class="card mb-3 widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="icon-wrapper rounded">
                                            <div class="icon-wrapper-bg bg-warning"></div>
                                            <BsFillPieChartFill />

                                        </div>
                                        <div class="widget-numbers">
                                            <span>{deposit}</span>
                                        </div>
                                        <div class="widget-subheading fsize-1 pt-2 opacity-10 text-warning font-weight-bold">
                                            Cash Deposits
                                        </div>
                                        {/* <div class="widget-description opacity-8">
                                            <span class="text-danger pr-1">
                                                <BiChevronDown />
                                                <span class="pl-1">54.1% </span>
                                            </span>
                                            Down last 30 days
                                        </div> */}
                                    </div>
                                    <div class="widget-chart-wrapper">
                                        <div
                                            id="dashboard-sparklines-simple-1"
                                            tyle={{ minHeight: "121px" }}
                                        >
                                            <div
                                                id="apexchartspzuy8j6a"
                                                class="apexcharts-canvas apexchartspzuy8j6a"
                                                tyle={{ width: "502px", height: "121px" }}
                                            >
                                                <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="10" height="10">
                                                        <animate
                                                            attributeName="rx"
                                                            values="0;5;0"
                                                            dur="10s"
                                                            repeatCount="indefinite" />
                                                    </rect>
                                                </svg>
                                                <div class="apexcharts-legend"></div>
                                            </div>
                                        </div>
                                        <div class="resize-triggers">
                                            <div class="expand-trigger">
                                                <div style={{ width: "502px", height: "121px" }}></div>
                                            </div>
                                            <div class="contract-trigger"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-xl-4">
                                <div class="card mb-3 widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="icon-wrapper rounded">
                                            <div class="icon-wrapper-bg bg-danger"></div>
                                            <DiGoogleAnalytics />
                                        </div>
                                        <div class="widget-numbers">
                                            <span>0</span>
                                        </div>
                                        <div class="widget-subheading fsize-1 pt-2 opacity-10 text-warning font-weight-bold">
                                            Sharpe Ratio
                                        </div>
                                        <div class="widget-description opacity-8">
                       

                                            {/* <span class="text-info pl-1">
                                                <BiChevronDown />
                                                <span class="pl-1"> good </span>
                                            </span> */}
                                        </div>
                                    </div>
                                    <div class="widget-chart-wrapper">
                                        <div
                                            id="dashboard-sparklines-simple-1"
                                            tyle={{ minHeight: "121px" }}
                                        >
                                            <div
                                                id="apexchartspzuy8j6a"
                                                class="apexcharts-canvas apexchartspzuy8j6a"
                                                tyle={{ width: "502px", height: "121px" }}
                                            >
                                                <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="10" height="10">
                                                        <animate
                                                            attributeName="rx"
                                                            values="0;5;0"
                                                            dur="10s"
                                                            repeatCount="indefinite" />
                                                    </rect>
                                                </svg>
                                                <div class="apexcharts-legend"></div>
                                            </div>
                                        </div>
                                        <div class="resize-triggers">
                                            <div class="expand-trigger">
                                                <div style={{ width: "502px", height: "121px" }}></div>
                                            </div>
                                            <div class="contract-trigger"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-xl-4">
                                <div class="card mb-3 widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="icon-wrapper rounded">
                                            <div class="icon-wrapper-bg bg-info"></div>
                                            <GiCutDiamond />
                                        </div>
                                        <div class="widget-numbers text-danger">
                                            <span>{withdrawl}</span>
                                        </div>
                                        <div class="widget-subheading fsize-1 pt-2 opacity-10 text-warning font-weight-bold">
                                            Withdrawals
                                        </div>
                                        {/* <div class="widget-description opacity-8">
                                            Down by
                                            <span class="text-success pl-1">
                                                <BiChevronUp />
                                                <span class="pl-1">21.8% </span>
                                            </span>
                                        </div> */}
                                    </div>
                                    <div class="widget-chart-wrapper">
                                        <div
                                            id="dashboard-sparklines-simple-1"
                                            tyle={{ minHeight: "121px" }}
                                        >
                                            <div
                                                id="apexchartspzuy8j6a"
                                                class="apexcharts-canvas apexchartspzuy8j6a"
                                                tyle={{ width: "502px", height: "121px" }}
                                            >
                                                <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="10" height="10">
                                                        <animate
                                                            attributeName="rx"
                                                            values="0;5;0"
                                                            dur="10s"
                                                            repeatCount="indefinite" />
                                                    </rect>
                                                </svg>
                                                <div class="apexcharts-legend"></div>
                                            </div>
                                        </div>
                                        <div class="resize-triggers">
                                            <div class="expand-trigger">
                                                <div style={{ width: "502px", height: "121px" }}></div>
                                            </div>
                                            <div class="contract-trigger"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center mbg-3 mt-3 mb-3">
                                <button class="btn-wide btn-pill btn-shadow fsize-1 btn btn-focus btn-lg" onClick={handleNavigaterecords}>
                                    <span class="mr-2 opacity-7 Report_btn">
                                        <AiOutlineGlobal />
                                    </span>
                                    View Complete Report
                                </button>
                            </div>
                        </div>
                    </div>
                </section>



            </div>

            {/* <div className="table-bx mt-4">
                <div className="col-lg-6 col-sm-6">
                    <h3>Transaction Record</h3>
                </div>
                <div className="row">
                    <div className="col-lg-12  orc-table openttrade_transtion text-center">
                        <div className="table-responsive ">
                            <table className="table border" id="accordionExample1">
                                <thead className="table-dark" id="headingOne">
                                    <tr>
                                        <th scope="col" className="col-2">
                                            Transaction ld
                                        </th>
                                        <th scope="col" className="col-1">
                                            Type
                                        </th>
                                        <th scope="col" className="col-1">
                                            Date
                                        </th>
                                        <th scope="col" className="col-2">
                                            Amount ({currencySymbol?.code})
                                        </th>
                                        <th scope="col" className="col-4">
                                            Running Transaction ({currencySymbol?.code})
                                        </th>
                                        <th scope="col" className="col-1">
                                            Comments
                                        </th>
                                        <th
                                            scope="col"
                                            className="accordion-button collapsed col-2"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample1"
                                >
                                    {transactionList.map((val, ind) => {
                                        return (
                                            <tr key={ind} className="accordion-body">
                                                <th>{val.transactionNo}</th>
                                                <td>{val.type}</td>
                                                <td>{moment(val.transDate).format("DD MMM YYYY")}</td>
                                                <td>
                                                    {currencySymbol?.symbol} {val.amount}
                                                </td>
                                                <td>
                                                    {currencySymbol?.symbol} {val.runningBalance}
                                                </td>
                                                <td>{val.comments}</td>
                                                <td></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample1"
                            >
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    setPagination={setPagination}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                    pagination={pagination}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-6 col-sm-6">
                        <h3>Trade Record</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12  orc-table openttrade_transtion text-center">
                        <div className="table-responsive mb-5">
                            <table className="table border" id="accordionExample">
                                <thead className="table-dark " id="headingOne">
                                    <tr>
                                        <th scope="col" className="col-1">
                                            Name
                                        </th>
                                        <th scope="col" className="col-1">
                                            Product
                                        </th>
                                        <th scope="col" className="col-1">
                                            Open/Close
                                        </th>
                                        <th scope="col" className="col-1">
                                            Long/Short
                                        </th>
                                        <th scope="col" className="col-1">
                                            Date
                                        </th>
                                        <th scope="col" className="col-1">
                                            Quantity
                                        </th>
                                        <th scope="col" className="col-4">
                                            Buy Price({currencySymbol?.code})
                                        </th>
                                        <th scope="col" className="col-1">
                                            Margin
                                        </th>
                                        <th
                                            scope="col"
                                            className="accordion-button col-1"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody
                                    id="collapseOne"
                                    className="accordion-collapse  collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    {openTradeList?.map((val, ind) => {
                                        let stockArr = val.stockCode.split("_");
                                        return (
                                            <tr key={ind}>
                                                <th>&nbsp;{stockArr[2]}</th>
                                                <td>&nbsp;{stockArr[2] + "/" + stockArr[3]}</td>
                                                <td>&nbsp;{val.isOpen ? "Open" : "Close"}</td>
                                                <td>&nbsp;{val.short}</td>
                                                <td>
                                                    &nbsp;{moment(val.openDate).format("DD MMM YYYY")}
                                                </td>
                                                <td>&nbsp;{Number(val.quantity).toFixed(2)}</td>
                                                <td>
                                                    &nbsp;{currencySymbol?.symbol} {val.transFee}
                                                </td>
                                              
                                                <td>&nbsp;{Number(val.margin)}</td>
                                                <td></td>
                                            
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <Pagination
                                    currentPage={currentPageTrade}
                                    totalPages={totalPagesTrade}
                                    onPageChange={handlePageChangeTrade}
                                    setPagination={setPaginationTrade}
                                    pageNumber={pageNumberTrade}
                                    pageSize={pageSizeTrade}
                                    pagination={setPaginationTrade}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default PortfolioTable;
