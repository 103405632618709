import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { getorderHistory } from '../../../services/TradeService';
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';
const OrderHistory = ({ transactionId, orderhistorysymbol }) => {
const [orderHistoryData, setOrderHistoryData] = useState([])

  useEffect(() => {
    if (transactionId) {
      fetchOrderHistory(transactionId);
    }
  }, [transactionId]);

  const fetchOrderHistory = async (id) => {
    try {
      const response = await getorderHistory(id);
      if (response && response.data) {     
        setOrderHistoryData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
    }
  };

  return (
    <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="OrderHistory" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
      <div className="modal-dialog modal-lg  modal-dialog-top">
        <div className="modal-content modal_order_history">
          <div className="modal-header p-0 border-bottom-0">
            {/* <h5 className="modal-title h4 buySell_main" id="exampleModalLgLabel">Order History</h5> */}
            <button type="button" className="btn-close btn-close-provider" id='closeBuySell' data-bs-dismiss="modal" aria-label="Close" >×</button>
          </div>



          {/* new modal rder history */}
          <div class="container order_history">
            <div class="col-md-12 mb-2">
              <h2 class="main-head text-center">Order History</h2>
            </div>
            <div class="row justify-content-center scroll_order_history">

              <div class="col-md-4"></div>
              {orderHistoryData.map((val) => {

let stocksplit= val.stockName.split('/')

                let stockcurrency = CountryCurrencyList.filter((e) => e.code == stocksplit[1])
              
                let portfoliocurrency = CountryCurrencyList.filter((e) => e.code == val.portfolioCurrecy)

                return (
                  <div class="col-md-12 ">
                    <div class="pricing-table turquoise mb-1">


                      <div class="pricing-features row">
                        <div class="feature col-4 col-md-4"> <p>{moment(val.tradeDate).format('DD MMM YYYY')}</p></div>
                        <div class="feature col-4 col-md-4 price_center">

                          <p className={val.short == 'buy' ? 'green1' : 'red1'} >{val.short}</p>
                        </div>
                        <div className="feature col-4 col-md-4 price_right">
                          <p><b style={{ fontWeight: 'bold' }}>{val.qty}</b></p>
                        </div>

                        <div class="feature col-4 col-md-4"> <p>{stockcurrency[0]?.symbol_native} {val.stockPrice}</p></div>
                        <div class="feature col-4 col-md-4 price_center"> </div>

                        <div class="feature col-4 col-md-4 price_right"> <p>{portfoliocurrency[0]?.symbol_native} {val.valueInShares}</p></div>
                      </div>

                    </div>
                  </div>
                )

              })
              }

              <div class="col-md-4"></div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default OrderHistory