import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { addCummissionAPI } from '../../../services/ProviderServices';
import { toast } from 'react-toastify';

const AddCummission = ({inputCummission, setInputCummission}) => {
    const { aboveTransValue, upToTransValue, commission, type, comments } = inputCummission
    const [buttonDisable,setButtonDisable]=useState(false)
    let validationSchema = Yup.object().shape({
        aboveTransValue: Yup.number('Above must be number').required('Above is required').typeError('Above must be a number'),
        upToTransValue: Yup.number('Upto must be number').required('Upto is required').typeError('Upto must be a number'),
        commission: Yup.number('Commission must be number').required('Commission is required').typeError('Commission must be a number'),
        type: Yup.string().required('Type is required'),
    });
    const handleAddCommission=(value)=>{
        setButtonDisable(true)
        let cancle = document.getElementById('Cancle-Cummission')
        try {
            addCummissionAPI(value).then((res) => {
                
                if (res?.status === 200) {
                    setButtonDisable(false)
                    cancle.click() 
                }
            })
        } catch (error) {
            toast.error('Something went wrong. Please try again later.')
        }
    }
    const handleRemoveInputData=()=>{
        setInputCummission({ providerId: '', aboveTransValue: '', upToTransValue: '', commission: '', comments: '', type: 'Fixed', createdBy: '', modifiedBy: '' })
    }
    const handleInputCummissionChange=(e)=>{
        
        if(e.target.name=='aboveTransValue' || e.target.name=='upToTransValue' || e.target.name=='commission'){
            if(e.target.value>=0 || e.target.value == '.'){
                let n = e.target.value.split('.')
                var roundedNumber = e.target.value;
                var decimalIndex = roundedNumber.indexOf(".");
                if (decimalIndex !== -1) {
                  var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                  if (decimalPlaces > 2) {
                    roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                  }
                  
                }  
             
                if(n.length==2){
                    if (n[0].length <= 10) {
                        setInputCummission({...inputCummission,[e.target.name]:roundedNumber})
                      }
                }else if(n.length==1 && e.target.value.length <= 10){
                    setInputCummission({...inputCummission,[e.target.name]:roundedNumber})
                }
            }
        }else{
            setInputCummission({...inputCummission,[e.target.name]:e.target.value})
        }
    }
    const handleBlurCommissionInput=()=>{

    }
  return (
    <>
            <div className="modal fade show backdrop" data-bs-backdrop="static" id="addCummission" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content  provider-min-md">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Add Commission</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" onClick={handleRemoveInputData} aria-label="Close">×</button>
                        </div>

                        <div className="modal-body blackout-bx">
                            <Formik
                                initialValues={inputCummission}
                                enableReinitialize
                                validationSchema={validationSchema}
                                onSubmit={(value) => {
                                    handleAddCommission(value)
                                    
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form className='row p-3'>
                                        {/* <div class="col-sm-12"> */}
                                        <div class="col-12 col-sm-6">
                                            <label>Above <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" class="form-control input_font-Size" onBlur={handleBlurCommissionInput} name='aboveTransValue' value={aboveTransValue} onChange={handleInputCummissionChange} required="" id="autoSizingInput" placeholder="0" />
                                            {errors.aboveTransValue && touched.aboveTransValue ? (
                                                <div className='color-msg-err'>{errors.aboveTransValue}</div>
                                            ) : null}
                                        </div>
                                        <div class="col-12 col-sm-6 ms-auto">
                                            <label>Upto <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" name='upToTransValue' onBlur={handleBlurCommissionInput} class="form-control input_font-Size" value={upToTransValue} onChange={handleInputCummissionChange} required="" id="autoSizingInput" placeholder="0" />
                                            {errors.upToTransValue && touched.upToTransValue ? (
                                                <div className='color-msg-err'>{errors.upToTransValue}</div>
                                            ) : null}
                                        </div>
                                        <div class="col-12 col-sm-6 mt-3">
                                            <label>Commission <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" class="form-control input_font-Size" onBlur={handleBlurCommissionInput} name='commission' value={commission} required="" onChange={handleInputCummissionChange} id="autoSizingInput" placeholder="0" />
                                            {errors.commission && touched.commission ? (
                                                <div className='color-msg-err'>{errors.commission}</div>
                                            ) : null}
                                        </div>
                                        <div class="col-12 col-sm-6 ms-auto mt-3">
                                            <label>Type <span style={{ "color": "#f00" }}>*</span></label>
                                            <Field type="text" as="select" class="form-control input_font-Size form-select" onBlur={handleBlurCommissionInput} value={type} name='type' onChange={handleInputCummissionChange} required="" id="autoSizingInput" placeholder="">
                                                <option value="Fixed">Fixed</option>
                                                <option value="Percent">Percent</option>
                                            </Field>
                                            {errors.type && touched.type ? (
                                                <div className='color-msg-err'>{errors.type}</div>
                                            ) : null}
                                        </div>
                                        <div class="col-12 col-sm-12 ms-auto mt-3">
                                            <label>Comments</label>
                                            <Field type="text" as="textarea" class="form-control input_font-Size" name='comments' value={comments} onChange={handleInputCummissionChange} id="autoSizingInput" placeholder="" />
                                                                              
                                        </div>
                                        


                                        <div className='row'>
                                        <div className='col-md-6'>
                                            <button class="btn  ms-1 d-block mt-3 w-100" type='submit' disabled={buttonDisable} > Save </button>
                                        </div>
                                        <div className='col-md-6'>
                                            <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-Cummission' type='button' onClick={handleRemoveInputData} data-bs-dismiss="modal" aria-label="Close" >Cancel</button>
                                        </div>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default AddCummission