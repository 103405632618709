import React from 'react'

const DeleteConfirmation = ({ deleteConfirm, type }) => {

    // onClick={() => handleDeleteTrade(val.tradeId)}
    return (
        <>
            <div className="modal fade show backdrop" data-bs-backdrop="static" id="deleteModel" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ 'display': 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-md  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0 ps-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Are you sure?</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" aria-label="Close">×</button>
                        </div>
                        <div className='col-12'>
                            <p className='white_text' >
                                Do you really want to {type} ? This process cannot be undone.
                            </p>
                        </div>
                        <div className='row'>
                        <div className='col-md-6'>
                            <button class="btn  ms-1 d-block mt-3 w-100" type='submit' data-bs-dismiss="modal" aria-label="Close">Cancel </button>
                        </div>
                        <div className='col-md-6'>
                            <button class="btn ms-md-2 ms-2 d-block mt-3 w-100" id='Cancle-provider' type='button' onClick={() => deleteConfirm()} data-bs-dismiss="modal" aria-label="Close" >Ok</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteConfirmation