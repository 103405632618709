import React, { useEffect } from 'react'
import { useState } from 'react'
import '../../assets/css/home-scss/feedback.css'
import { RiFeedbackLine } from "react-icons/ri";
import { addFeedBack } from '../../services/ProviderServices';
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";

let validationSchema = Yup.object().shape({
    email:  Yup.string().email('Invalid email address').matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email address').required('Email is Required'),
    subject: Yup.string().required('Subject is required'),
    description: Yup.string().required('Description is required')
});

const Feedback = () => {
    const [toggle, settoggle] = useState("")
    const [feedBackInput, setFeedBackInput] = useState({ userId: '', description: '', subject: '', email: '',render:0 })
    const {description, subject, email, render}=feedBackInput
    
    let userData = JSON.parse(localStorage.getItem('userDetails'))

   



    const togglehandler = () => {
        if (toggle === "active") {
            settoggle("");
        }
        else {
            settoggle("active");
        }
        setFeedBackInput({ userId: '', description: '', subject: '', email: '' ,render:render+1})
    }





    const feedbackhandler = (data) => {

        try {
            addFeedBack(data).then((res) => {
                if (res?.status === 200) {
                    setFeedBackInput({ userId: '', description: '', subject: '', email: '' })
                }
            })
        } catch (error) {

        }
  

    }
    const handleChangeFeedBackInput=(e)=>{
        setFeedBackInput({...feedBackInput, [e.target.name]:e.target.value })
    }

    const handleFeedbackInputChange=()=>{

    }
    return (
        <section>
            <div class={`sidebar-contact ${toggle} bg-blue`}>
                <button class="toggle" onClick={togglehandler}> <RiFeedbackLine style={{ color: 'white', fontSize: '22px' }} /> </button>
                
                <div>
                <h2 className='white_text' >Feedback</h2>
                <button type="button" className="btn-close trade_table_close" data-bs-dismiss="modal" aria-label="Close" onClick={togglehandler} >×</button>
                </div>
              
                <div class={toggle== 'active'?"scroll feedback_input show":"scroll feedback_input fade"}>
                    <Formik
                        initialValues={feedBackInput}
                        validationSchema={toggle== 'active'&&validationSchema}
                        enableReinitialize={true}
                        onSubmit={(value) => {
                                value.userId=userData.userId
                                feedbackhandler(value)
                                // resetForm()
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                
                                        {/* <div class="postion-min-dta postion-frm"> */}
                                            <div class="row">

                                                <div class="col-sm-12 mb-1 ms-auto">
                                                    <Field type="text" name='email' value={email} onBlur={handleFeedbackInputChange} onChange={handleChangeFeedBackInput} placeholder='Email *' className="form-control mb-0" id="formGroupExampleInput" />
                                                    {errors.email && touched.email ? (
                                                        <div className='color-msg-err'>{errors.email}</div>
                                                    ) : null}
                                                </div>




                                                <div class="col-sm-12 mb-1 ms-auto">
                                                    <Field type="text" name='subject' value={subject} onBlur={handleFeedbackInputChange} onChange={handleChangeFeedBackInput} placeholder='Subject *' className="mb-0 form-control" id="formGroupExampleInput" />
                                                    {errors.subject && touched.subject ? (
                                                        <div className='color-msg-err'>{errors.subject}</div>
                                                    ) : null}
                                                </div>







                                                <div class="col-sm-12 mb-1 ms-auto">
                                                    <Field type="text" as="textarea" onBlur={handleFeedbackInputChange} onChange={handleChangeFeedBackInput} placeholder="Description here..*" name='description' value={description} className="mb-0 form-control" id="formGroupExampleInput" />
                                                    {errors.description && touched.description ? (
                                                        <div className='color-msg-err'>{errors.description}</div>
                                                    ) : null}
                                                </div>

                                                <div class="d-grid gap-2 d-md-block mt-2">
                                                    <button class="btn " type="submit">Submit</button>
                                                    <button class="btn ms-2" type="button" onClick={togglehandler}>Cancel</button>
                                                </div>
                                            </div>
                                   
                            </Form>
                        )}
                    </Formik>
                    {/* <form>
                        <input name="email" type="email" placeholder='Email' value={email} required/>
                        <input type='text' placeholder='Subject' name='subject'  value={subject} required/>
                        <textarea placeholder="description here.." name='description'  required></textarea>
                        <button type='button' className='submit-button' onClick={feedbackhandler}>Submit</button>
                    </form> */}
                </div>
            </div>
        </section>
    )
}

export default Feedback