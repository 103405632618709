import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
    'Authorization':API_KEY
}

export const getPortfolioReturn = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Providers/GetPortfolioReturn?UserId=${data.UserId}&PortfolioId=${data.PortfolioId}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        
    }
}
export const addAmountAPI = async (data) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddTransaction`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getTrangectionType = async (id) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeSystems?UserId=${id}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
    }
}

export const getTransactionListAPI = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTransactionByBroker?UserId=${data.id}&PortfolioId=${data.portfolioId}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
    }
}
export const getTransactionListFinancialAPI = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTransactionListByBroker?UserId=${data.id}&PortfolioId=${data.PortfolioId}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            
        }
        // 
    } catch (error) {
    }
}