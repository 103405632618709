import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}

export const addProvider = async (data) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Providers/AddProvider`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const addFeedBack = async (data) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Providers/AddFeedback`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const DeleteFeedBack = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Providers/DeleteFeedback?id=${id}`,
            mode: 'cors',
            headers,
            // data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}


export const getFeedBack = async (data) => {

    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/GetFeedbacks?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}`,
            mode: 'cors',
            headers,
            data
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const addCummissionAPI = async (data) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Providers/AddCommission`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const getProviders = async (data) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/GetProviders?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`,
            mode: 'cors',
            headers,
            data
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}



export const deleteProviders = async (id) => {
    try {
        const response = await axios({
            method: 'Delete',
            url: `${API_URL}/Providers/DeleteProvider?id=${id}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const updateProviders = async (data) => {
    try {
        const response = await axios({
            method: 'put',
            url: `${API_URL}/Providers/UpdateProvider?id=${data.providerId}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const addStocks = async (data) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Providers/AddStock`,
            mode: 'cors',
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            //     'Access-Control-Allow-Origin': '*',
            //     'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS'
            // },
            data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        toast.error('The file has some invalid data, please recheck and try again later.')
        return error
    }
}

export const GetStockByCountry = async (data) => {
    
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/GetCountryStocks?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}&country=${data.country}${data?.search?.length > 0 ? '&Searchtext=' + data.search : ''}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const GetProviderstocks = async (data) => {
    
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/GetProviderstocks?PageNumber=${data.PageNumber}&PageSize=${data.PageSize}&ProviderId=${data.id}${data?.search?.length > 0 ? '&Searchtext=' + data.search : ''}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const updateProviderStock = async (data) => {
    
    try {
        const response = await axios({
            method: 'put',
            url: `${API_URL}/Providers/UpdateStock?id=${data.stockId}`,
            mode: 'cors',
            headers,
            data
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}


export const GetProviderCummission = async (id) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/GetProvidersCommission?ProviderId=${id}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const updateProviderCommission = async (data) => {
    
    try {
        const response = await axios({
            method: 'put',
            url: `${API_URL}/Providers/UpdateCommission?id=${data.id}`,
            mode: 'cors',
            headers,
            data
        });
        if (response?.status === 200) {
            // NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const deleteProviderCommission = async (id) => {
    try {
        const response = await axios({
            method: 'Delete',
            url: `${API_URL}/Providers/DeleteCommission?id=${id}`,
            mode: 'cors',
            headers,
        });
 
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const GetCurrentBalabce = async (data) => {
    
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/GetCurrentBalance?UserId=${data.userId}&portfolioId=${data.portfolioId}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const addHelpVideo = async (data) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Providers/AddHelpVideo`,
            mode: 'cors',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
                'Authorization': API_KEY
            },
            data: data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const editHelpVideo = async (data,id) => {
    try {
        const response = await axios({
            method: 'Post',
            url: `${API_URL}/Providers/UpdateHelpVideo?TradingHelpId=${id}`,
            mode: 'cors',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
                'Authorization': API_KEY
            },
            data: data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}

export const GetHelpVideos = async (data) => {
    
    try {
        const response = await axios({
            method: 'get',
            url: `${API_URL}/Providers/GetHelpVideos?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}
export const deleteHelpVideoAPI = async (tradingHelpId) => {
    try {
        const response = await axios({
            method: 'Delete',
            url: `${API_URL}/Providers/DeleteHelpVideo?id=${tradingHelpId}`,
            mode: 'cors',
            headers,
        });
 
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
        // 
    } catch (error) {
        
    }
}
