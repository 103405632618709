import React, { useEffect, useState, useRef } from 'react'
import { GetHelpVideos } from '../../../services/ProviderServices';
import ReactPlayer from 'react-player'
// const fs = require('fs');


const VideoPlayList = ({ playvideo, setPlayVideo }) => {
    const [videoPlayList, setVideoPlayList] = useState([])
    const [selectedVideo, setSelectedVideo] = useState('')
    const [videothumbnails, setVideoThumbnails] = useState([])
    
    const getHelpVideo = () => {
        let pageData = {
            pageNumber: 1,
            pageSize: 100
        }

        try {
            GetHelpVideos(pageData).then((res) => {
                if (res?.status === 200) {
                    let data = res.data.data
                    // console.log(data)
                    data = data.map((video) => {

                        let thumbnail = getYouTubeThumbnail(video.helpNeme);
                        // console.log(thumbnail , "thumbnails")
                        return {
                            ...video,
                            videoThumbnail: thumbnail
                        };
                    });
                    // console.log(data , "data video")
                    const VideoUrl = "https://api.trading.tradinglounge.com/";

                    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;


                    let videoPath = data[0].helpNeme.replace(/\\/g, '/');

                    if (urlRegex.test(videoPath)) {

                        setSelectedVideo(videoPath);
                    } else {
                        setSelectedVideo(VideoUrl + videoPath);
                    }
                    // setSelectedVideo(data[0].helpNeme)
                    setVideoPlayList(data)
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        getHelpVideo()
    }, [])

    const handleSelectVideo = (data) => {
        const VideoUrl = 'https://api.crypto.tradinglounge.com/';
        // const VideoUrl = 'https://api.trading.tradinglounge.com/';
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

        let videoPath = data.helpNeme.replace(/\\/g, '/');

        if (urlRegex.test(videoPath)) {

        
            var youtubeThumbnail = require('youtube-thumbnail');

            var thumbnail = youtubeThumbnail(videoPath);
            setVideoThumbnails(thumbnail.default)
        
            setSelectedVideo(videoPath);
            setPlayVideo(true)
        } else {
          
            setSelectedVideo(VideoUrl + videoPath);
        }
    }
    const handleCloseButtonClick = () => {
        setPlayVideo(false);
        // Additional logic you want to execute when the button is clicked
    };
    const getYouTubeThumbnail = (videoUrl) => {
        try {
            const youtubeThumbnail = require('youtube-thumbnail');

            const videoId = extractYouTubeVideoId(videoUrl);


            const thumbnail = youtubeThumbnail(`https://www.youtube.com/watch?v=${videoId}`);

            return thumbnail.default.url;
        } catch (error) {
            console.error('Error fetching YouTube thumbnail:', error);
            return '';
        }
    };


    const extractYouTubeVideoId = (videoUrl) => {

        const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = videoUrl.match(regExp);

        if (match && match[1]) {
            return match[1];
        } else {
            throw new Error('Invalid YouTube URL');
        }
    };
    return (
        <div className='modal fade hide backdrop-1' id="Videoplayclick" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLgLabel" aria-modal="true" role="dialog" style={{ "display": "none" }}>
            <div class="modal-dialog modal-lg about-data-lg">
                <div class="modal-content cal-model">
                    <div class="modal-header">
                        <h5 class="modal-title h4" id="exampleModalLgLabel">Help Videos</h5>
                        <button type="button" className=" ms-auto button-padding" data-bs-dismiss="modal" aria-label="Close" id="openTradeModelClose" onClick={handleCloseButtonClick} >×</button>
                    </div>
                    <div class="modal-body">
                        {/* <!-- ==data start ====  --> */}
                        <div class=" column_gap-12">
                            {/* <!-- ===left side bar==== --> */}
                            <div class=" lef_side_bar left-bar-height-issue flex-shrink-0">



                                <div class="vid-main-wrapper clearfix">
                                    <div class="vid-container">
                                        {/* <ReactPlayer url={`https://api.trading.tradinglounge.com/${selectedVideo}`} /> */}
                                        <ReactPlayer url={selectedVideo} controls={true}
                                            playing={playvideo}
                                            onError={(e) => console.log('Error occurred:', e)} />

                                        {/* <video id="vid_frame" src={`https://local.tradinglounge.com/${selectedVideo}`} frameborder="0" width="560" height="315"></video> */}
                                    </div>

                                    <div class="vid-list-container">
                                        <ol id="vid-list">
                                            {
                                                videoPlayList.map((val, ind) => {
                                                    const DEFAULT_THUMBNAIL_URL = 'https://i.ytimg.com/vi/jEhwOtBwYoE/maxresdefault.jpg';
                                                    return (
                                                        <li>
                                                            <a href="#" onClick={() => handleSelectVideo(val)}>
                                                                {/* <span class="vid-thumb"><img width={72} src={`https://api.trading.tradinglounge.com/${val.helpNeme}`} /></span> */}

                                                                <span class="vid-thumb"><img width={72} src={val.videoThumbnail || DEFAULT_THUMBNAIL_URL} /></span>
                                                                <div class="desc">{val.moduleNeme}</div>
                                                            </a>
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ol>


                                    </div>
                                </div>




                                {/* <!--Container Main start--> */}


                            </div>
                            {/* <!-- ===left side bar==== --> */}


                        </div>



                        {/* <!-- ==data start ====  --> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default VideoPlayList