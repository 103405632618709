import React from 'react'

const Summary = () => {
    return (
        <>
            <div className="modal fade show backdrop-1" data-bs-backdrop="static" id="summary" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ display: 'none' }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg modal-lg-extra-width  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Summary</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" aria-label="Close">×</button>
                        </div>

                        <div className="modal-body blackout-bx">
                            {/* <!-------modeal-----> */}

                            <div class="row">

                                <section>

                                    <div class="container">
                                        <div class="row">
                                            <div class="col-sm-6">

                                                <div class="postion-bx-min mt-4">
                                                    <span class="frm-hd-t">Search</span>

                                                    <div class="postion-frm mt-2">
                                                        <form>
                                                            <div class="row mb-3">
                                                                <label for="inputEmail3" class=" col-lg-2    col-form-label col-form-label">Provider</label>
                                                                <div class="col-lg-9   ms-auto">
                                                                    <select class="form-select" type="select" id="autoSizingSelect"
                                                                        required="required">
                                                                        <option selected>---Select---</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                        <option value="3">Three</option>
                                                                    </select>
                                                                </div>
                                                            </div>


                                                            <div class="row mb-3 radio-input">
                                                                {/* <!-- <label for="inputEmail3" class="col-sm-3 col-form-label">long / short</label> --> */}
                                                                <div class="col-lg-9 col-md-12 ms-auto">
                                                                    <input class="form-check-input" type="radio" name="gridRadios" required
                                                                        id="gridRadios1" value="option1" checked />
                                                                    <label class="form-check-label " for="gridRadios1">
                                                                        Both
                                                                    </label>

                                                                    <input class="form-check-input" type="radio" name="gridRadios" required
                                                                        id="gridRadios2" value="option2" />
                                                                    <label class="form-check-label " for="gridRadios2">
                                                                        Long
                                                                    </label>


                                                                    <input class="form-check-input" type="radio" name="gridRadios" required
                                                                        id="gridRadios3" value="option3" />
                                                                    <label class="form-check-label " for="gridRadios3">
                                                                        Short
                                                                    </label>

                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-sm-6">
                                                <div class="postion-bx-min mt-4">
                                                    <span class="frm-hd-t">Time Frame</span>
                                                    <div class="postion-frm mt-2">
                                                        <form>
                                                            <div class="row mb-3">
                                                                <label for="inputEmail3" class="col-lg-3 col-form-label">Select Year </label>
                                                                <div class="col-lg-9 ms-auto">
                                                                    <select class="form-select" type="select" id="autoSizingSelect"
                                                                        required="required">
                                                                        <option selected>---Select---</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                        <option value="3">Three</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="row mb-2 ">
                                                                {/* <!-- <label for="inputEmail3" class="col-sm-3 col-form-label">long / short</label> --> */}
                                                                <div class="col-sm-12 col-lg-9 ms-auto">

                                                                    <div class="row">
                                                                        <div class="col-sm-6">
                                                                            <div class="row">
                                                                                <label for="inputEmail3"
                                                                                    class="col-sm-3 col-form-label">Form</label>
                                                                                <div class="col-sm-9">
                                                                                    <input type="email" class="form-control" id="inputEmail3" />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-sm-6">
                                                                            <div class="row ">
                                                                                <label for="inputEmail3" class="col-sm-2 col-form-label">To</label>
                                                                                <div class="col-sm-10">
                                                                                    <input type="email" class="form-control" id="inputEmail3" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 mt-4">
                                                <div class="table-responsive">
                                                    <div class="table-input text-center">
                                                        <table class="table table-bordered mt-2 table-input-data">

                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="text-center">Current Balance</th>
                                                                    <th scope="col" class="text-center">Balance in CFD</th>
                                                                    <th scope="col" class="text-center">Balance in Hand</th>
                                                                    <th scope="col" class="text-center">Balance in Crypto </th>
                                                                    <th scope="col" class="text-center"> Profit Loss </th>
                                                                    <th scope="col" class="text-center">Leverage</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr>
                                                                    <th class="col-sm-2">0,000,0000</th>
                                                                    <td class="col-sm-2">0,000,0000</td>
                                                                    <td class="col-sm-2 ">0,000,0000</td>
                                                                    <td class="col-sm-2">0,000,0000</td>
                                                                    <td class="col-sm-2">0,000,0000</td>
                                                                    <td class="col-sm-2">0,000,0000</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>






                                <section class="mt-5">

                                    <div class="container">
                                        <div class="row">
                                            <div class="col-sm-12 tabs-sec">
                                                <ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                                            data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                                            aria-selected="true">Monthly Summary </button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                            data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                                            aria-selected="false">Strategy Results</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                                            data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                                                            aria-selected="false">Monthly Profit and Los</button>
                                                    </li>
                                                </ul>

                                                <div class="tab-content" id="pills-tabContent">

                                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                                        aria-labelledby="pills-home-tab">
                                                        <div class="table-responsive">
                                                            <div class="table-input1 text-center">
                                                                <table class="table table-bordered  table-input-data1">

                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center">Running Balance</th>
                                                                            <th class="text-center">Monthly P/L </th>
                                                                            <th class="text-center">Total </th>
                                                                            <th class="text-center">Win</th>
                                                                            <th class="text-center">Loss</th>
                                                                            <th class="text-center">Win Loss</th>
                                                                            <th class="text-center">Max Win</th>
                                                                            <th class="text-center">Max Loss</th>
                                                                            <th class="text-center">Opened Trades</th>
                                                                            <th class="text-center">R-Multiple</th>
                                                                            <th class="text-center">Expectancy </th>
                                                                            <th class="text-center">Fees</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                                        aria-labelledby="pills-profile-tab">
                                                        <div class="table-responsive">
                                                            <div class="table-input1 text-center">
                                                                <table class="table table-bordered  table-input-data1">

                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center">Running Balance</th>
                                                                            <th class="text-center">Monthly P/L </th>
                                                                            <th class="text-center">Total </th>
                                                                            <th class="text-center">Win</th>
                                                                            <th class="text-center">Loss</th>
                                                                            <th class="text-center">Win Loss</th>
                                                                            <th class="text-center">Max Win</th>
                                                                            <th class="text-center">Max Loss</th>
                                                                            <th class="text-center">Opened Trades</th>
                                                                            <th class="text-center">R-Multiple</th>
                                                                            <th class="text-center">Expectancy </th>
                                                                            <th class="text-center">Fees</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                                                        aria-labelledby="pills-contact-tab">
                                                        <div class="table-responsive">
                                                            <div class="table-input1 text-center">
                                                                <table class="table table-bordered  table-input-data1">

                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-center">Running Balance</th>
                                                                            <th class="text-center">Monthly P/L </th>
                                                                            <th class="text-center">Total </th>
                                                                            <th class="text-center">Win</th>
                                                                            <th class="text-center">Loss</th>
                                                                            <th class="text-center">Win Loss</th>
                                                                            <th class="text-center">Max Win</th>
                                                                            <th class="text-center">Max Loss</th>
                                                                            <th class="text-center">Opened Trades</th>
                                                                            <th class="text-center">R-Multiple</th>
                                                                            <th class="text-center">Expectancy </th>
                                                                            <th class="text-center">Fees</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <th class="">0,000,0000</th>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class=" ">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                            <td class="">0,000,0000</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </section>






























                            </div>





                        </div>

                    </div>
                    {/* <!-------modeal-----> */}



                </div>
            </div>
        </>
    )
}

export default Summary