import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getStockNews } from '../../../../services/TradeService'

const StockNews = ({ typeData, tradeCode }) => {
	const [stockNewsList, setStockNewsList] = useState([])
	const [stockNewsListStatus, setStockNewsListStatus] = useState(false)
	const [newsDate, setNewDate] = useState('1D')
	useEffect(() => {
		if (typeData === 'News') {
			try {
				getStockNews(newsDate,tradeCode).then((res) => {
					
					setStockNewsList(res.data)
					setStockNewsListStatus(true)
				})
			} catch (error) {
				
			}
		}
	}, [typeData, newsDate])
	return (
		<>
			<div class="tab-pane fade news-ul" id="pills-news" role="tabpanel"
				aria-labelledby="pills-news-tab">
				<div className='row'>
					<div class="row">
						<div class="col-sm-12 ">
							<ul class="slec-dta-ch text-end">
								<li className={newsDate === '1D' && 'day-active white'} onClick={() => setNewDate('1D')}><Link className={newsDate === '1D' && ' white'}>1D</Link></li>
								<li className={newsDate === '1W' && 'day-active white'} onClick={() => setNewDate('1W')}><Link className={newsDate === '1W' && ' white'}>1W</Link></li>
								<li className={newsDate === '1M' && 'day-active white'} onClick={() => setNewDate('1M')}><Link className={newsDate === '1M' && ' white'}>1M</Link></li>
								{/* <li ><Link className={newsDate==='1Y'&&'day-active white'} onClick={()=>setNewDate('1Y')}>1Y</Link></li>
									<li ><Link className={newsDate==='3Y'&&'day-active white'} onClick={()=>setNewDate('3Y')}>3Y</Link></li>
									<li ><Link className={newsDate==='5Y'&&'day-active white'} onClick={()=>setNewDate('5Y')}>5Y</Link></li> */}
								{/* <li><Link onclick={handleSelectDate}>max</Link></li>
									<li><Link onclick={handleSelectDate}>Sip</Link></li> */}
							</ul>
						</div>

					</div>
				</div>
				{
					 stockNewsListStatus==false ?
						<div class="container-fluid">
							<div class="container">
								<div class="row">
									<div class="col-md-12">
										<div class="preloader1">
											{/* <div class="loader loader-inner-1"> */}
											<div class="loader loader-inner-2">
												<div class="loader loader-inner-3">
												</div>
											</div>
											{/* </div> */}
										</div>
									</div>
								</div>
							</div>
						</div> :stockNewsList.length >0?
						<div class="row ">
							<div class="col-sm-12 mt-5">

								<div class="row">
									{
										stockNewsList?.map((val, ind) => {
											let date2 = new Date(val.datetime)
											const date1 = new Date();
											const diffTime = Math.abs(date2 - date1);
											const numberOfDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
											let numberOfHours = Math.ceil(numberOfDays * 24);
											let numberOfMinutes = Math.ceil(diffTime / (1000 * 60 * 60 * 24)/ 86400 * 60)
											
											let _date
											if(numberOfDays>1){
												_date=numberOfDays+' day ago'
											}else if(numberOfHours>0){
												_date=numberOfHours+' hours ago'
											}
											else if(numberOfMinutes>0){
												_date=numberOfMinutes+' minute ago'
											}
											return (
												<Link to={val.url} target='_blank'>
													<div class="col-sm-12 summary-news-div">


														<div class="new-bx">
															<div class="d-flex position-relative">
																<img src={val.imageUrl} class="flex-shrink-0 h-127 img-fluid me-3 col-lg-2"
																	alt="..." />
																<div>
																	<h5 class="mt-0">{val.headline}</h5>
																	<span>{_date} .</span>
																	<spn className='sroce-nm'>{val.source}</spn>
																	{/* <p className='summary-news'>{val.summary}</p> */}
																</div>
															</div>
														</div>
													</div>
												</Link>
											)
										})
									}


								</div>
							</div>




						</div>:<div className='w-100 text-center'>
										<h4 className='no-data'>Data Not Available</h4>
									</div>
				}


				{/* <ul class="slec-dta-ch new-ul">
					<li><a href="">Dec '21</a></li>
					<li><a href="">Dec '21</a></li>
					<li><a href="">Dec '21</a></li>
					<li><a href="">Dec '21</a></li>
					<li><a href="">Dec '21</a></li>

				</ul> */}
			</div>
		</>
	)
}

export default StockNews