import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
    'Authorization':API_KEY
}


export const gettradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTrades?UserId=${data?.id}&pageNumber=${data?.pageNumber}&pageSize=${data?.pageSize}&type=${data.type}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const modifyOpenTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/ModifyTrade`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const getorderHistory = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeHistory?TransactionNo=${data}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getClosetradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetCloseTradeList?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const GetOpenTradeListByUserId = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTradeList?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&&type=${data.type}&portfolioId=${data.portfolioId}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const updateOpenTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdateOpenTrade?transactionNo=${data.transactionNo}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const RemoveUserTrade = async (TradeId , UserId) => {
    // debugger
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/RemovePendingTrade?TradeId=${TradeId}&UserId=${UserId}`,

            mode: 'cors',
            headers,
            
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}


export const getOpentradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTrades?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&UserId=${data.id}&PortfolioId=${data.portfolioId}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getdepositwithdrawl = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Providers/GetBalanceStats?UserId=${data.UserId}&portfolioId=${data.portfolioId}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getOpentradeListFinancelYear = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetUserTrades?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&UserId=${data.id}&PortfolioId=${data.portfolioId}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const deleteTrade = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteTrade?id=${id}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const closeOpenTrade = async (id) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${API_URL}/Trades/CloseTrade?tradeid=${id}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }
    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const editTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdateTrade?TradeId=${data?.tradeId}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getWatchList = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetWatchList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&UserId=${data.id}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const removeWatchList = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteWatchList?id=${id}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const AddWatchList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddWatchList`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getStockDetails = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDetails/${data.tradeCode}/${data.stockDate}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getRapidStockDetails = async (from, to) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Rapid/GetStockDetails/${from}-${to}`,
      mode: "cors",
      headers,
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};


export const getStockChartDetails = async () => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDetails/AAPL/1w`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getStockNews = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Crypto/GetCryptoNews/CRYPTO:${tradeCode}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getRapidStockNews = async (tradeCode) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Rapid/GetStockNews/${tradeCode}`,
      mode: "cors",
      headers,
      // data: {
      //   firstName: 'Fred',
      //   lastName: 'Flintstone'
      // }
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};


export const getStockIncomeStatement = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockIncomeStatement/${tradeCode}/30`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getStockBalanceSheet = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockBalanceSheet/${tradeCode}/30`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getStockCashFlow = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockCashFlow/${tradeCode}/30`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const AddOpenTradeAPI = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddOpenTrade`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const getInflationRate = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/getInflationRate`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const GetInvestmentReturnWeekly = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Crypto/GetInvestmentReturnWeekly/${data.CurrencyFrom}/${data.CurrencyTo}`,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const GetInvestmentReturnMonthly = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Crypto/GetInvestmentReturnMonthly/${data.CurrencyFrom}/${data.CurrencyTo}`,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getDevident = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDividends/${data.tradeCode}/${data.interval}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getStockStats = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockStats/${stockCode}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const getCryptoIcons = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `https://rest.coinapi.io/v1/assets/icons/32`,
            mode: 'cors',
            headers:{
                'X-CoinAPI-Key':'6AE6BEA7-932F-4087-BEB0-1F39CB915396'
            },
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getCryptoSymbols = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Crypto/GetIcons`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}