import React, { useEffect, useRef, useState } from 'react'
import { createChart, ColorType } from 'lightweight-charts';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

const LineChart = ({  chartType, symbol }) => {

    const [showSideBar, setShowSideBar] = useState(true)
    const [stockDate,setStockDate]=useState('1')
    const chartRef = useRef()
    let tvScriptLoadingPromise;


    useEffect(
        () => {
            chartRef.current = createWidget;
         
            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => chartRef.current && chartRef.current());
            function createWidget() {
                
                if (document.getElementById('tradingview_ac040') && 'TradingView' in window) {
                    const widget = window.tvWidget = new window.TradingView.widget({
                        autosize: true,
                        symbol: `${symbol}USD`,
                        interval: stockDate,
                        timezone: "Etc/UTC",
                        theme: "light",
                        style: chartType,
                        locale: "en",
                        toolbar_bg: "#f1f3f6",
                        // enable_publishing: true,
                        // withdateranges: true,
                        // range: "YTD",
                        "hide_top_toolbar": true,
                        hide_side_toolbar: showSideBar,
                        allow_symbol_change: true,
                        save_image: false,
                        hide_legend: true,
                        // details: true,
                        // studies: [
                        //     "ElliottWave@tv-basicstudies",
                        // ],
                        // show_popup_button: true,
                        container_id: "tradingview_ac040"
                    });
                    return () => {
                        widget.remove();
                    };
                }
            }

        },
        [chartType, showSideBar,stockDate,symbol]
    );
    
    // document.querySelector('.tv-header__link').remove();

    const handleSelectDate = (n) => {
		setStockDate(n)
	}
    return (
        <>
            <div className='tradingview-widget-container-crypto'>
                <button className={showSideBar ? 'show-widgth-button show-widgth-button-hide' : 'show-widgth-button show-widgth-button-show'} onClick={() => showSideBar ? setShowSideBar(false) : setShowSideBar(true)}>{showSideBar ? <IoIosArrowForward /> : <IoIosArrowBack />}</button>
                <div id='tradingview_ac040' className='tradingview_5327b-css-crypto' />
                <p ref={chartRef}></p>
                <div class="col-sm-12 ">
                    <ul class="slec-dta-ch-o">
                        <li className={stockDate === '1' && 'day-active white'} onClick={() => handleSelectDate('1')}><Link className={stockDate === '1' && 'day-active white'}>1m</Link></li>
                        <li className={stockDate === '5' && 'day-active white'} onClick={() => handleSelectDate('5')}><Link className={stockDate === '5' && 'day-active white'}>5M</Link></li>
                        <li className={stockDate === '30' && 'day-active white'} onClick={() => handleSelectDate('30')}><Link className={stockDate === '30' && 'day-active white'}>30M</Link></li>
                        <li className={stockDate === '60' && 'day-active white'} onClick={() => handleSelectDate('60')}><Link className={stockDate === '60' && 'day-active white'}>1H</Link></li>
                        <li className={stockDate === 'D' && 'day-active white'} onClick={() => handleSelectDate('D')}><Link className={stockDate === 'D' && 'day-active white'}>1D</Link></li>
                        <li className={stockDate === 'W' && 'day-active white'} onClick={() => handleSelectDate('W')}><Link className={stockDate === 'W' && 'day-active white'}>1W</Link></li>

                        {/* <li><Link onclick={handleSelectDate}>max</Link></li>
									<li><Link onclick={handleSelectDate}>Sip</Link></li> */}
                    </ul>
                </div>
            </div>
            {/* <div className='tradingview-widget-container'>
				<div id='tradingview_fca25' />
				<div className="tradingview-widget-copyright">
					<a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets</span></a> on TradingView
				</div>
			</div> */}
        </>
    )
}

export default LineChart