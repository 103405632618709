import React, { useEffect, useState } from "react";
import { getRapidStockDetails, getStockDetails } from "../../../services/TradeService";
import StockHeader from "./ModelSetting/StockHeader";
import StockIncomeStateMent from "./ModelSetting/StockIncomeStateMent";
import StockNews from "./ModelSetting/StockNews";
import StockOverview from "./ModelSetting/StockOverview";
import StockBalanceSheet from "./ModelSetting/StockBalanceSheet";
import StockCashFlow from "./ModelSetting/StockCashFlow";
import {
  getExchangeRateAPI,
  getRapidExchangeRateAPI,
} from "../../../services/commonServices";

const TradeModel = ({ tradeCode }) => {
  // debugger;

  const [stockData, setStockData] = useState([]);
  const [stockDate, setStockdate] = useState("1H");
  const [typeData, setTypeData] = useState("Overview");
  const [tradeP, setTradeP] = useState({ ask_price: 0, bid_price: 0 });
  useEffect(() => {
    // debugger;
    let codeArr = tradeCode.split("_");
    let data = {
      tradeCode,
      stockDate,
    };
    if (
      tradeCode !== undefined &&
      tradeCode !== "" &&
      stockData !== undefined
    ) {
      try {
        // getSearchStockDetails(codeArr[2]).then((res) => {
        //   // console.log("stockdd", res?.data);
        //   setStockData(res?.data || []);
        // });
      } catch (error) {}
    }
  }, [stockDate, typeData, tradeCode]);

  if (stockData != null && stockData != undefined) {
    const timeSeriesData = stockData["Time Series (Daily)"];
    let dataArr = [];
    // Iterate over the date keys and their corresponding data
    for (const date in timeSeriesData) {
      if (timeSeriesData.hasOwnProperty(date)) {
        const dailyData = timeSeriesData[date];
        const open = parseFloat(dailyData["1. open"]);
        const high = parseFloat(dailyData["2. high"]);
        const low = parseFloat(dailyData["3. low"]);
        const close = parseFloat(dailyData["4. close"]);
        const volume = parseInt(dailyData["5. volume"]);

        // Parse the date string into a JavaScript Date object
        const parsedDate = new Date(date);
        let n = {
          open: open,
          close: close,
          high: high,
          low: low,
          date: parsedDate,
        };
        dataArr.push(n);
      }
      setStockData(dataArr);
    }
  }

  function removeTrailingZeros(number) {
    // Convert the number to a string and use a regular expression
    // to remove trailing zeros and the decimal point if it's followed by only zeros.
    const result = number.toString().replace(/(\.[0-9]*[1-9])0+$/, "$1");

    return result;
  }
  useEffect(() => {
    // debugger;
    const fetchIt = async () => {
      let codeArr = tradeCode.split("_");
      let data = {
        tradeCode,
        stockDate,
      };
      if (
        tradeCode !== undefined &&
        tradeCode !== "" &&
        stockData !== undefined &&
        codeArr !== undefined
      ) {
         let newstockCodeArr = [];
         if (
           codeArr[3] === "USDC" ||
           codeArr[3] === "USDT" ||
           codeArr[3] === "USDB"
         ) {
          //  debugger;
           newstockCodeArr = "USD";
         } else {
           newstockCodeArr = codeArr[3];
         }
        // console.log("codeArr", codeArr);
        try {
          // getExchangeRateAPI(codeArr[2],'usd').then((respons) => {
          const respons = await getRapidExchangeRateAPI(
            codeArr[2],
            newstockCodeArr
          );
          if (respons.data) {
              // console.log("resppp", respons.data)
              const bidPrice = respons.data.rates?.[newstockCodeArr];
            const askPrice = respons.data.rates?.[newstockCodeArr];
            // console.log("ask", respons.data.rates?.[codeArr[3]], askPrice);
              localStorage.setItem("askPrice", JSON.stringify(askPrice || 0));
            localStorage.setItem("bidPrice", JSON.stringify(bidPrice || 0));
            localStorage?.setItem("tradeCode", JSON.stringify(tradeCode || "N/A"))
              setTradeP({
                ask_price: askPrice,
                bid_price: bidPrice,
              });
            }
         
        } catch (error) {}
      }
    };

    fetchIt()
  }, [tradeCode]);

  //  useEffect(() => {
  //    debugger;
  //    let codeArr = tradeCode.split("_");
  //    let data = {
  //      tradeCode,
  //      stockDate,
  //    };
  //    if (
  //      tradeCode !== undefined &&
  //      tradeCode !== "" &&
  //      stockData !== undefined &&
  //      codeArr !== undefined
  //    ) {
  //      // console.log("codeArr", codeArr);
  //      try {
  //        // getExchangeRateAPI(codeArr[2],'usd').then((respons) => {
  //        getRapidExchangeRateAPI(codeArr[2], codeArr[3]).then((respons) => {
  //          if (respons.data) {
  //            // console.log("resppp", respons.data)
  //            const bidPrice = respons.data.rates?.[codeArr[3]];
  //            const askPrice = respons.data.rates?.[codeArr[3]];
  //            // console.log("ask", respons.data.rates?.[codeArr[3]], askPrice);
  //            setTradeP({
  //              ask_price: askPrice,
  //              bid_price: bidPrice,
  //            });
  //            setPrice(respons.data.rates?.[codeArr[3]]);
  //          }
  //        });
  //      } catch (error) {}
  //    }
  //  }, [tradeCode]);
  // console.log("yuptradecode", tradeCode);
  return (
    <>
      <div
        className="modal fade show backdrop"
        data-bs-backdrop="static"
        id="StockView"
        tabindex="-1"
        aria-labelledby="exampleModalLgLabel"
        style={{ display: "none" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content pt-0">
            <div className="modal-body blackout-bx pb-0">
              {/* <!-------modeal-----> */}

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                ×
              </button>

              <div className="row">
                <StockHeader
                  tradeCode={tradeCode}
                  tradeP={tradeP}
                  stockData={stockData}
                  stockDate={stockDate}
                />
                <div class="bot-brd "></div>
                <div class="col-sm-12 mt-3">
                  <div class="detels-bx">
                    {/* <ul class="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-overview-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-overview" type="button" role="tab" aria-controls="pills-overview"
                          aria-selected="true" onClick={() => setTypeData('Overview')}>Overview</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-cashflow-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-cashflow" type="button" role="tab"
                          aria-controls="pills-cashflow" aria-selected="false" onClick={() => setTypeData('CashFlow')}>CashFlow</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-news-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-news" type="button" role="tab" aria-controls="pills-news"
                          aria-selected="false" onClick={() => setTypeData('News')}>News</button>
                      </li>

                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-balancesheet-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-balancesheet" type="button" role="tab"
                          aria-controls="pills-balancesheet" aria-selected="false" onClick={() => setTypeData('BalanceSheet')}>Balance Sheet</button>
                      </li>



                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-incomeStatement-tab" data-bs-toggle="pill"
                          data-bs-target="#pills-incomeStatement" type="button" role="tab"
                          aria-controls="pills-incomeStatement"
                          aria-selected="false" onClick={() => setTypeData('IncomeStatement')}>Income Statement</button>
                      </li>

                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="pills-tabContent">
              {/* {
              stockData?.length>0?<StockOverview stockData={stockData}/>:<h5 className="modal-title h4" id="exampleModalLgLabel">No Data Found</h5>
            } */}
              <StockOverview
                tradeP={tradeP}
                tradeCode={tradeCode}
                stockData={stockData}
                stockDate={stockDate}
                setStockdate={setStockdate}
              />
              <StockCashFlow tradeCode={tradeCode} typeData={typeData} />
              <StockBalanceSheet tradeCode={tradeCode} typeData={typeData} />
              <StockNews tradeCode={tradeCode} typeData={typeData} />
              <StockIncomeStateMent tradeCode={tradeCode} typeData={typeData} />
            </div>
            {/* <!-------modeal-----> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeModel;
